*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #101820;
  font-family: 'Lato';
  line-height: normal;
}

body{
  background-color: #fff;
}


.justify-wrap{
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus::-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.container{
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
  max-width: 100%;
}

.wrapper{
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100%;
}

.container{
  width: 100%;
  max-width: 1158px;
}

.promo__sl-box{
  position: relative;
}

.page{
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.promo__sl-item-img{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
}

.nav__sl-box{
  position: absolute;
  z-index: 5;
  width: 144px;
  left: 50%;
  margin-left: -72px;
  bottom: 72px;
}

.nav__sl-arrow{
  width: 72px;
  height: 72px;
  min-width: 72px;
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  cursor: pointer;
  &:not(.nav__sl-gr):hover{
    background: rgba(0, 0, 0, 0.95);
  }
}

.promo__sl-info-box{
  left: 0;
  width: 100%;
  z-index: 5;
  position: absolute;
  bottom: 177px;
  margin-bottom: 28px;
}

.promo__sl-info{
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: 'Garamond';
  padding: 0 11px;
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
  &:before{
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.01);
    filter: blur(10px);
    backdrop-filter: blur(15px);
  }
  span{
    position: relative;
    z-index: 3;
  }
}

.promo__sl-box{
  font-size: 0;
}

.sc__promo{
  position: relative;
}

.scroll__down{
  width: 1px;
  left: 50%;
  background: #A57C52;
  position: absolute;
  height: 48px;
  bottom: -24px;
  z-index: 10;
  cursor: pointer;
  &:before{
    content: '';
    top: -10px;
    right: -15px;
    left: -15px;
    bottom: -10px;
    position: absolute;
  }
  &:after{
    content: '';
    left: 0;
    top: 0;
    background: #fff;
    position: absolute;
    height: 24px;
    width: 100%;
  }
}

.sc__common{
  .container{
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.sc__header{
  text-align: center;
  margin-bottom: 48px;
}

.sc__title{
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.2em;
  font-family: 'Garamond';
  text-transform: uppercase;
  &:not(:last-child){
    margin-bottom: 6px;
  }
}

.sc__subtitle{
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.2em;
  font-family: 'Garamond';
  text-transform: uppercase;
}

.sc__info{
  .sc__header{
    margin-bottom: 66px;
  }
  .actions__wrap{
    padding-top: 6px;
  }
}

.promo__sl{
  position: relative;
  &:after{
    content: '';
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    opacity: 0.1;
  }
}

.text{
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em;
}

.sc__box{
  &:not(:last-child){
    margin-bottom: 66px;
  }
}

.info__item-title{
  font-size: 34px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 29px;
  font-family: 'Garamond';
  margin-top: -7px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.info__item-descr{
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #101820;
  padding-right: 10px;
  padding-left: 10px;
}

.info__item{
  &:not(:last-child){
    border-right: 1px solid #BDBDBD;
  }
}

.btn__custom{
  height: 72px;
  padding: 0 50px;
  min-width: 360px;
  text-transform: uppercase;
  border: 1px solid #8B6E4B;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3em;
  color: #A57C52;
  span{
    position: relative;
    z-index: 3;
  }
  position: relative;
  &:before{
    content: '';
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    position: absolute;
    background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
    opacity: 0;
  }
  cursor: pointer;
  &:hover{
    color: #fff !important;
    &:before{
      opacity: 1;
    }
  }
}

.page:not(.page-ie){
  .gr-item{
    background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.topics__item-bl{
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 0;
  padding-top: 177.77778%;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.75;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
  &:hover{
    &:before{
      opacity: 0.65;
    }
  }
}

.topics__item-ct{
  position: absolute;
  top: 0;
  z-index: 3;
  left: 0;
  padding: 48px 30px;
  width: 100%;
  text-align: center;
}

.topics__item-title{
  margin-bottom: 24px;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15em;
  font-family: 'Garamond';
  text-transform: uppercase;
}

.topics__item-descr{
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #fff;
}

.advants__slider-row{
  margin-left: -12px;
  margin-right: -12px;
}

.adv__sl-item{
  padding-right: 12px;
  padding-left: 12px;
}

.adv__sl-item-img{
  margin-bottom: 12px;
  img{
    display: block;
    width: 100%;
  }
}

.adv__sl-item-descr{
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.adv__sl-item-descr-box{
  height: 48px;
}

.nav__sl-gr{
  background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
  backdrop-filter: blur(10px);
}


.advant__slider-box{
  position: relative;
  .nav__sl-arrow{
    position: absolute;
    top: 135px;
    margin-top: -36px;
    &.nav__sl-arrow-left{
      left: 50%;
      margin-left: -720px;
    }
    &.nav__sl-arrow-right{
      right: 50%;
      margin-right: -720px;
    }
  }
}

.nav__sl-gr{
  &:hover{
    opacity: 0.75;
  }
}

.slick-dots{
  font-size: 0;
  text-align: center;
  margin: 48px 0 0;
  li{
    display: inline-block;
    vertical-align: top;
    &:not(:last-child){
      margin-right: 21px;
    }
    button{
      border: none;
      width: 12px;
      cursor: pointer;
      height: 12px;
      background: rgba(16, 24, 32, 0.25);
      border-radius: 12px;
      transform: scale(0.5);
      &:hover{
        transform: scale(1);
      }
    }
    &.slick-active{
      button{
        transform: scale(1);
        background: #A57C52;
      }
    }
  }
}

.sc__partners{
  .sc__header{
    height: 144px;
    margin-bottom: 24px;
    position: relative;
  }
}

.respect__decor{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1128px;
  margin-top: -72px;
  margin-left: -564px;
  img{
    display: block;
    width: 100%;
  }
}

.partners__item{
  width: 20%;
  min-width: 20%;
  text-align: center;
}

.partners__item-country{
  margin-bottom: 8px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #A57C52;
}

.partners__item-type{
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  margin-bottom: 8px;
}

.partners__item-name-box{
  height: 60px;
  margin-bottom: 8px;
}

.partners__item-bl{
  cursor: pointer;
  position: relative;

}

.partners__item-name{
  font-size: 24px;
  line-height: 30px;
  font-family: 'Garamond';
  letter-spacing: 0.02em;
}

.partners__list{
  padding-bottom: 24px;
  border-bottom: 1px solid #8B6E4B;
}

.partners__item-modal{
  position: absolute;
  width: 320px;
  left: 50%;
  margin-left: -160px;
  top: -74px;
  background: #fff;
  z-index: 2;
  cursor: default;
  padding: 48px;
  box-shadow: 0px 0px 145px rgba(0, 0, 0, 0.15), 0px 0px 84.919px rgba(0, 0, 0, 0.113889), 0px 0px 46.1852px rgba(0, 0, 0, 0.0911111), 0px 0px 23.5625px rgba(0, 0, 0, 0.075), 0px 0px 11.8148px rgba(0, 0, 0, 0.0588889), 0px 0px 5.70602px rgba(0, 0, 0, 0.0361111);
}

.partners__item-logo{
  height: 72px;
  margin-bottom: 24px;
}

.info__close{
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 0;
  width: 48px;
  height: 48px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
  &:hover{
    path{
      stroke: #101820;
    }
  }
}

.partners__item:first-child{
  .partners__item-modal{
    left: 0;
    margin-left: 0;
  }
}

.partners__item:last-child{
  .partners__item-modal{
    left: auto;
    right: 0;
    margin-left: 0;
  }
}


.logo__img{
  img{
    display: block;
    max-width: 100%;
  }
}

.partners__item-modal{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  text-align: left;
  .partners__item-name{
    margin-bottom: 24px;
  }
  &.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .text{
    text-align: left;
    font-size: 12px;
    line-height: 140%;
  }
  .logo__img{
    img{
      max-height: 72px;
    }
  }
}

.sc__contacts{
  .container{
    padding-top: 96px;
  }
  .text{
    margin-bottom: 48px;
  }
}

.map, .map1, .map2{
  height: 464px;
}

.footer{
  background: #101820;
  .container{
    padding-top: 96px;
    padding-bottom: 72px;
  }
}

.row__custom{
  margin-left: -12px;
  margin-right: -12px;
}

.col__custom{
  padding-left: 12px;
  padding-right: 12px;
}

.footer__phone{
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.05em;
  font-weight: 300;
  color: #fff;
  a{
    color: #fff !important;
    &:hover{
      color: rgba(255, 255, 255, 0.65) !important;
    }
  }
}

.footer__nav{
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      display: block;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
      &:not(:last-child){
        margin-bottom: 12px;
      }
      a{
        color: #fff !important;
        &:hover{
          color: rgba(255, 255, 255, 0.65) !important;
        }
      }
    }
  }
}

.footer__logo{
  width: 134px;
  height: 94px;
  margin: 0 auto 36px;
  opacity: 0.5;
  display: block;
  &:hover{
    opacity: 1;
  }
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.footer__social{
  a{
    width: 16px;
    height: 16px;
    opacity: 0.35;
    &:hover{
      opacity: 1;
    }
    svg{
      display: block;
      width: 100%;
      height: 100%;
    }
    &:not(:last-child){
      margin-right: 24px;
    }
  }
}

.footer__info{
  margin-top: 12px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.5);
}

.content{
  position: relative;
}

.scroll__up{
  width: 72px;
  height: 144px;
  background: #fff;
  left: 50%;
  margin-left: 540px;
  bottom: 0;
  z-index: 50;
  cursor: pointer;
  position: absolute;
  &:hover{
    svg{
      opacity: 0.65;
    }
  }
}

.slogan__box{
  position: absolute;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  top: 284px;
  left: 12px;
  transform: rotate(
                  -90deg
  );
  transform-origin: left top;
}

.slogan{
  font-family: 'Garamond';
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.header{

  top: 0;
  z-index: 20;
  /*left: 0;
  position: absolute;*/
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1440px;
  .container{
    padding: 24px 24px 24px 108px;
    max-width: 100%;
  }
}

.page-not-front, .header-white-mode{
  .header{
    background: linear-gradient(
                    180deg
            , #FFFFFF 0%, rgba(255, 255, 255, 0.8) 100%) !important;
    backdrop-filter: blur(12px) !important;
  }
}

.page{
  position: relative;
}

.logo__box{
  width: 96px;
  img{
    display: block;
    width: 100%;
  }
}

.header__phone{
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.05em;
  color: #fff;
  a{
    color: #fff !important;
    &:hover{
      color: rgba(255, 255, 255, 0.6) !important;
    }
  }
}

.header__phone-box{
  height: 48px;
}

.hd__item{
  padding: 0 24px;
  a{
    height: 48px;
    cursor: pointer;
  }
  position: relative;
  &:after{
    content: '';
    background: rgba(255, 255, 255, 0.25);
    width: 1px;
    right: 0;
    top: 0;
    height: 40px;
    position: absolute;
  }
  &:last-child{
    padding-right: 0;
    &:after{
      display: none !important;
    }
  }
}

a:hover, .view__item-bl:hover, .view__item-bl.active{
  .icon__custom{
    &:before{
      opacity: 0;
    }
    &:after{
      opacity: 1;
    }
  }
}

.icon__custom{
  position: relative;
  width: 48px;
  height: 48px;
  &:before, &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:after{
    opacity: 0;
  }
}



.icon__lobby{
  &:before{
    background-image: url("../img/infra/icon-lobby.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-lobby-gr.svg");
  }
}

.icon__cons{
  &:before{
    background-image: url("../img/infra/icon-cons.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-cons-gr.svg");
  }
}

.icon__pool{
  &:before{
    background-image: url("../img/infra/icon-pool.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-pool-gr.svg");
  }
}

.icon__fit{
  &:before{
    background-image: url("../img/infra/icon-fit.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-fit-gr.svg");
  }
}

.icon__spa{
  &:before{
    background-image: url("../img/infra/icon-spa.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-spa-gr.svg");
  }
}

.icon__wine{
  &:before{
    background-image: url("../img/infra/icon-wine.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-wine-gr.svg");
  }
}

.icon__rest{
  &:before{
    background-image: url("../img/infra/icon-rest.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-rest-gr.svg");
  }
}

.icon__cinema{
  &:before{
    background-image: url("../img/infra/icon-cinema.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-cinema-gr.svg");
  }
}

.icon__comp{
  &:before{
    background-image: url("../img/infra/icon-comp.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-comp-gr.svg");
  }
}

.icon__comp2{
  &:before{
    background-image: url("../img/infra/icon-comp2.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-comp2-gr.svg");
  }
}

.icon__phone{
  &:before{
    background-image: url("../img/icons/icon-phone.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-phone-gr.svg");
  }
}

.icon__user{
  &:before{
    background-image: url("../img/icons/icon-user.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-user-gr.svg");
  }
}

.icon__ru{
  &:before{
    background-image: url("../img/icons/icon-ru.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-ru-gr.svg");
  }
}

.icon__en{
  &:before{
    background-image: url("../img/icons/icon-en.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-en-gr.svg");
  }
}

.icon__bars{
  height: 102px;
  &:before{
    background-image: url("../img/icons/icon-bars.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-bars-gr.svg");
  }
}

.icon__cards{
  height: 36px;
  width: 36px;
  &:before{
    background-image: url("../img/icons/icon-cards.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-cards-gr.svg");
  }
}

.icon__list{
  height: 36px;
  width: 36px;
  &:before{
    background-image: url("../img/icons/icon-list.svg");
  }
  &:after{
    background-image: url("../img/icons/icon-list-gr.svg");
  }
}


.sc__promo{
  .slick-dots{
    margin: 0;
    position: absolute;
    width: 12px;
    right: 42px;
    top: 50%;
    z-index: 20;
    transform: translateY(-50%);
    li{
      display: block;
      &:not(:last-child){
        margin-right: 0;
        margin-bottom: 21px;
      }
      button{
        background: rgba(255, 255, 255, 0.25);
      }
      &.slick-active{
        button{
          background: #fff;
        }
      }
    }
  }
}

.dots{
 /* margin: 0;
  position: absolute;
  width: 12px;
  right: 42px;
  top: 147px;
  z-index: 20;*/

  margin: 0;
  position: fixed;
  width: 12px;
  right: auto !important;
  top: 147px;
  z-index: 20;
  left: 50%;
  margin-left: 666px;

  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      display: block;
      &:not(:last-child){
        margin-right: 0;
        margin-bottom: 16px;
      }
      a{
        border: none;
        display: block;
        width: 12px;
        cursor: pointer;
        height: 12px;
        background: rgba(16, 24, 32, 0.25);
        border-radius: 12px;
        transform: scale(0.5);
      }
      &.active{
        a{
          transform: scale(1);
          background: #A57C52;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px){
  .dots{
    ul{
      li{
        a{
          &:hover{
            transform: scale(1);
            background-color: #A57C52;
          }
        }
      }
    }
  }
}

.social{
  position: absolute;
  z-index: 20;
  bottom: 72px;
  right: 40px;
  width: 16px;
  a{
    display: block;
    &:not(:last-child){
      margin-right: 0;
      margin-bottom: 18px;
    }
    &:hover{
      opacity: 0.65;
    }
  }
}

.page-front{
  .header{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.callback__toggle{
  background: url("../img/callback.svg") no-repeat center;
  background-size: contain;
  width: 96px;
  height: 96px;
  position: absolute;
  left: 50%;
  bottom: -48px;
  z-index: 20;
  margin-left: -612px;
  cursor: pointer;
  &:hover{
    .icon__callback{
      transform: rotateY(180deg);
    }
  }
}

.disabled-item{
  position: relative;
  &:before{
    content: '';
    z-index: 4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  a{
    opacity: 0.4;
  }
}

.icon__callback{
  background: url("../img/callback-phone.svg") no-repeat center;
  background-size: contain;
  width: 22px;
  height: 22px;
}

.nav__box{
  position: fixed;
  top: 0;
  z-index: 300;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  &.active{
    opacity: 1;
    visibility: visible;
  }
  .container{
    height: 100%;
  }
}

.nav__box-bg-left{
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  height: 100%;
  width: 50%;
}


.nav__box-bg-right{
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  height: 100%;
  width: 50%;
}



@media screen and (min-width: 1024px) {
  .nav__box{
    .container{
      transform: translateY(-100%);
      -webkit-transition-delay: 0.5s;
      transition-delay: 0.5s;
    }
  }


  .nav__box-bg-left{
    transform: translateY(-100%);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .nav__box-left, .nav__close{
    transform: translateY(-100%);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .nav__box-bg-right{
    transform: translateY(-100%);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .nav__menu{
    li{
      opacity: 0;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
  }

  .nav__box.active{
    .nav__box-bg-left, .nav__box-bg-right, .nav__box-left, .nav__close{
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: -webkit-transform 0.7s;
      transition: -webkit-transform 0.7s;
      transition: transform 0.7s;
      transition: transform 0.7s, -webkit-transform 0.7s;
    }
    .nav__box-bg-left, .nav__box-left{
      -webkit-transition-delay: 0.5s;
      transition-delay: 0.5s;
    }
    .container{
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: -webkit-transform 0.7s;
      transition: -webkit-transform 0.7s;
      transition: transform 0.7s;
      transition: transform 0.7s, -webkit-transform 0.7s;
    }
    .nav__menu{
      li{
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        &:nth-child(1){
          -webkit-transition: 0.8s opacity 1.4s,0.8s -webkit-transform 1.4s;
          transition: 0.8s opacity 1.4s,0.8s -webkit-transform 1.4s;
          transition: 0.8s opacity 1.4s,0.8s transform 1.4s;
          transition: 0.8s opacity 1.4s,0.8s transform 1.4s,0.8s -webkit-transform 1.4s;
        }
        &:nth-child(2){
          -webkit-transition: 0.8s opacity 1.3s,0.8s -webkit-transform 1.3s;
          transition: 0.8s opacity 1.3s,0.8s -webkit-transform 1.3s;
          transition: 0.8s opacity 1.3s,0.8s transform 1.3s;
          transition: 0.8s opacity 1.3s,0.8s transform 1.3s,0.8s -webkit-transform 1.3s;
        }
        &:nth-child(3){
          -webkit-transition: 0.8s opacity 1.2s,0.8s -webkit-transform 1.2s;
          transition: 0.8s opacity 1.2s,0.8s -webkit-transform 1.2s;
          transition: 0.8s opacity 1.2s,0.8s transform 1.2s;
          transition: 0.8s opacity 1.2s,0.8s transform 1.2s,0.8s -webkit-transform 1.2s;
        }
        &:nth-child(4){
          -webkit-transition: 0.8s opacity 1.1s,0.8s -webkit-transform 1.1s;
          transition: 0.8s opacity 1.1s,0.8s -webkit-transform 1.1s;
          transition: 0.8s opacity 1.1s,0.8s transform 1.1s;
          transition: 0.8s opacity 1.1s,0.8s transform 1.1s,0.8s -webkit-transform 1.1s;
        }
        &:nth-child(5){
          -webkit-transition: 0.8s opacity 1s,0.8s -webkit-transform 1s;
          transition: 0.8s opacity 1s,0.8s -webkit-transform 1s;
          transition: 0.8s opacity 1s,0.8s transform 1s;
          transition: 0.8s opacity 1s,0.8s transform 1s,0.8s -webkit-transform 1s;
        }
        &:nth-child(6){
          -webkit-transition: 0.8s opacity 0.9s,0.8s -webkit-transform 0.9s;
          transition: 0.8s opacity 0.9s,0.8s -webkit-transform 0.9s;
          transition: 0.8s opacity 0.9s,0.8s transform 0.9s;
          transition: 0.8s opacity 0.9s,0.8s transform 0.9s,0.8s -webkit-transform 0.9s;
        }
        &:nth-child(7){
          -webkit-transition: 0.8s opacity 0.8s,0.8s -webkit-transform 0.8s;
          transition: 0.8s opacity 0.8s,0.8s -webkit-transform 0.8s;
          transition: 0.8s opacity 0.8s,0.8s transform 0.8s;
          transition: 0.8s opacity 0.8s,0.8s transform 0.8s,0.8s -webkit-transform 0.8s;
        }
        &:nth-child(8){
          -webkit-transition: 0.8s opacity 0.7s,0.8s -webkit-transform 0.7s;
          transition: 0.8s opacity 0.7s,0.8s -webkit-transform 0.7s;
          transition: 0.8s opacity 0.7s,0.8s transform 0.7s;
          transition: 0.8s opacity 0.7s,0.8s transform 0.7s,0.8s -webkit-transform 0.7s;
        }
        &:nth-child(9){
          -webkit-transition: 0.8s opacity 0.6s,0.8s -webkit-transform 0.6s;
          transition: 0.8s opacity 0.6s,0.8s -webkit-transform 0.6s;
          transition: 0.8s opacity 0.6s,0.8s transform 0.6s;
          transition: 0.8s opacity 0.6s,0.8s transform 0.6s,0.8s -webkit-transform 0.6s;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .nav__box{
    background-color: #fff;
  }

  .nav__box-bg-left, .nav__box-bg-right{
    display: none !important;
  }
}


.nav__box-left{
  padding-left: 75px;
  padding-right: 28px;
}

.nav__logo{
  width: 160px;
  margin-bottom: 46px;
  img{
    display: block;
    width: 100%;
  }
}

.nav__data-box{
  &:not(:last-child){
    margin-bottom: 29px;
  }
}

.nav__box-ct{
  width: 100%;
}

.nav__data-box-label{
  margin-bottom: 3px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.05em;
}

.nav__data-box-value{
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.02em;
  font-family: 'Garamond';
  a{
    color: #101820 !important;
    &:hover{
      opacity: 0.75;
    }
  }
}

.nav__menu{
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      display: block;
      text-transform: uppercase;
      font-family: 'Garamond';
      font-size: 30px;
      line-height: 30px;
      letter-spacing: 0.15em;
      &:not(:last-child){
        margin-bottom: 38px;
      }
      a{
        &:hover{
          opacity: 0.65;
        }
      }
    }
  }
}

.nav__social{
  margin-top: 29px;
  margin-left: -3px;
  a{
    display: flex;
    &:not(:last-child){
      margin-right: 48px;
    }
    &:hover{
      opacity: 0.65;
    }
    width: 30px;
    height: 30px;
  }
}

.icon__insta-gr, .icon__fb-gr, .icon__yt-gr, .icon__em-gr, .icon__vk-gr, .icon__zen-gr, .icon__tg-gr{
  width: 30px;
  height: 30px;
  display: block;
}

.icon__insta-gr{
  background: url("../img/icons/icon-insta-gr.svg") no-repeat center;
  background-size: contain;
}

.icon__fb-gr{
  background: url("../img/icons/icon-fb-gr.svg") no-repeat center;
  background-size: contain;
}

.icon__yt-gr{
  background: url("../img/icons/icon-yt-gr.svg") no-repeat center;
  background-size: contain;
}

.icon__vk-gr{
  background: url("../img/icons/icon-vk-gr.svg") no-repeat center;
  background-size: contain;
}

.icon__tg-gr{
  background: url("../img/icons/icon-tg-gr.svg") no-repeat center;
  background-size: contain;
}

.icon__zen-gr{
  background: url("../img/icons/icon-zen-gr.svg") no-repeat center;
  background-size: contain;
}


.icon__em-gr{
  background: url("../img/icons/icon-em-gr.svg") no-repeat center;
  background-size: contain;
}

.nav__close{
  position: absolute;
  width: 96px;
  height: 96px;
  cursor: pointer;
  right: 0;
  z-index: 10;
  top: 0;
  &:hover{
    path{
      stroke: #101820;
    }
  }
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.overflow{
  overflow: hidden !important;
}

.sc__inside-start{
  .container{
    padding-top: 48px;
  }
}

.page-not-front{
  .content{
    padding-top: 144px;
  }
  .content{
    min-height: calc(100vh - 356px);
  }
  .section{
    &:last-child{
      .container{
        padding-bottom: 96px;
      }
      &.sc__partners{
        .container{
          padding-bottom: 96px;
        }
      }
      &.sc__panorama{
        .container{
          padding-bottom: 0;
        }
      }
      &.sc__results{
        .container{
          padding-bottom: 116px;
        }
      }
      &.sc__about-comfort{
        .container{
          padding-bottom: 72px;
        }
      }
    }
  }

  .respect__decor{
    position: relative;
    top: 0;
    margin-top: 0;
    left: 0;
    margin-left: 0;
    margin-bottom: 48px;
  }

  .sc__partners{
    .sc__header{
      height: auto;
    }
  }
  .partners__list{
    padding-bottom: 0;
    border: none;
  }
}

.page-not-front, .header-white-mode{


  &:not(.page-ie){
    .slogan {
      background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .header__phone {
    color: #101820;
    a{
      color: #101820 !important;
    }
  }

  .icon__phone{
    &:before{
      background-image: url("../img/icons/icon-phone-bl.svg");
    }
  }

  .icon__user{
    &:before{
      background-image: url("../img/icons/icon-user-bl.svg");
    }
  }

  .icon__ru{
    &:before{
      background-image: url("../img/icons/icon-ru-bl.svg");
    }
  }

  .icon__en{
    &:before{
      background-image: url("../img/icons/icon-en-bl.svg");
    }
  }

  .icon__bars{
    &:before{
      background-image: url("../img/icons/icon-bars-bl.svg");
    }
  }

  .hd__item:after {
    background: rgba(16, 24, 32, 0.25);
  }
}

.icon__back{
  width: 24px;
  height: 6px;
  min-width: 24px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.back__wrap{
  position: fixed;
  top: 97px;
  left: 50%;
  margin-left: -470px;
  z-index: 70;
  a{
    cursor: pointer;
    padding-bottom: 12px;
    font-size: 11px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #8C9091 !important;
    border-bottom: 1px solid #8C9091;
    &:hover{
      .icon__back{
        path{
          fill: #101820;
        }
      }
      color: #101820 !important;
      border-bottom: 1px solid #101820;
    }
  }
  span{
    margin-left: 12px;
  }
}

.page-contacts{
  .map{
    height: 600px;
  }
}

.map__box{
  padding-left: 48px;
}

.sc__contacts-inside{
  .nav__data-box:not(:last-child) {
    margin-bottom: 48px;
  }
  .nav__social{
    margin-top: 48px;
    a {
      width: 26px;
      height: 26px;
      &:not(:last-child) {
        margin-right: 24px;
      }
      svg{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sc__content-border{
  position: relative;
  &:before{
    content: '';
    top: -24px;
    width: 1px;
    left: 0;
    bottom: 0;
    background: #A57C52;
    position: absolute;
  }
  &.brd-indent-1{
    &:before{
      bottom: -114px;
    }
  }
  &.brd-indent-2{
    &:before{
      top: -100px;
    }
  }
}

.facade__sl-box{
  padding-right: 48px;
}

.sc__filter-facade{
  .sc__header{
    margin-bottom: 36px;
  }
}

.contacts__info{
  margin-top: 48px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #333333;
}

.footer{
  position: relative;
  .callback__toggle{
    top: -48px;
  }
}

.page-front{
  .footer{
    .callback__toggle{
      display: none !important;
    }
  }
}

.infra__list{
  .row{
    margin-left: -60px;
    margin-right: -60px;
  }
}

.infra__item{
  padding-left: 60px;
  padding-right: 60px;
}

.infra__item-title{
  position: relative;
  z-index: 3;
}

.infra__item-bl{
  position: relative;
  height: 386px;
  padding: 36px;
}

@media screen and (min-width: 1025px){
  .infra__item-bl{
    &:hover{
      .infra__item-bg-2{
        opacity: 1;
      }
      .infra__item-title{
        background: transparent !important;
        color: #fff !important;
        -webkit-text-fill-color: #fff !important;
      }
      .infra__item-img:before{
        opacity: 0;
      }
      .infra__item-img{
        &:after{
          border-width: 2px !important;
        }
      }
    }
  }
}


.infra__item:nth-child(1){
  .infra__item-bl{
    &:hover{
      .infra__item-arrow {
        right: 192px;
      }
    }
  }
}

.infra__item:nth-child(2){
  .infra__item-bl{
    &:hover{
      .infra__item-arrow {
        left: 192px;
      }
    }
  }
}

.content__box{
  &:not(:last-child){
    margin-bottom: 48px;
  }
}

.infra__item-bg-1{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  svg{
    width: 100%;
    height: 100%;
    display: block;
  }
}

.infra__item-bg-2{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  svg{
    width: 100%;
    height: 100%;
    display: block;
  }
}

.infra__item{
  &:nth-child(1){
    margin-top: 36px;
  }
}

.infra__item-img{
  width: 360px;
  position: absolute;
  right: -36px;
  top: -36px;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
  }
  img{
    display: block;
    width: 100%;
  }
  &:after{
    content: '';
    position: absolute;
    top: 36px;
    right: 36px;
    bottom: 0;
    left: 0;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}

.infra__item-arrow{
  position: absolute;
  width: 252px;
  height: 6px;
  top: 72px;
  right: 72px;
  z-index: 5;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.infra__item-title{
  font-size: 24px;
  line-height: 30px;
  font-family: 'Garamond';
  letter-spacing: 0.15em;
  text-transform: uppercase;
  span{
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
}

.infra__item{
  &:nth-child(2){
    .infra__item-img{
      right: auto;
      top: auto;
      bottom: -36px;
      left: -36px;
      &:after{
        top: 0;
        right: 0;
        bottom: 36px;
        left: 36px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-top: none;
        border-right: none;
      }
    }
    .infra__item-arrow{
      top: auto;
      right: auto;
      bottom: 72px;
      left: 72px;
    }
  }
}

.btn__list{
  .btn__custom {
    padding: 0 20px;
    min-width: 360px;
    &:not(:last-child){
      margin-right: 24px;
    }
  }
}

.text{
  h4{
    margin: 0 0 48px 0;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Garamond';
    font-weight: normal;
    letter-spacing: 0.02em;
    color: #333333;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0 0 -24px 0;
    li{
      margin: 0 0 24px 0;
      padding-left: 36px;
      position: relative;
      &:before{
        width: 6px;
        height: 6px;
        position: absolute;
        top: 9px;
        left: 9px;
        content: '';
        background: #A57C52;
        border-radius: 50%;
      }
    }
    &.list-col-2{
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      margin-right: -12px;
      li{
        width: calc(50% - 24px);
        min-width: calc(50% - 24px);
        margin-left: 12px;
        margin-right: 12px;
      }
    }
    &.list-col-3{
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      margin-right: -12px;
      li{
        width: calc(33.333% - 24px);
        min-width: calc(33.333% - 24px);
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }
}

.sc__panorama{
  .container{
    padding-top: 72px;
  }
}

.sc__content-border:before{
  z-index: 2;
}

.page{
  overflow: hidden;
}

.content__box-dark{
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;
  &:before{
    content: '';
    top: 0;
    bottom: 0;
    left: -2000px;
    right: -2000px;
    background: #EFEFEF;
    position: absolute;
  }
  & > *{
    position: relative;
    z-index: 2;
  }
}

.box__3d{
  position: relative;
  img{
    display: block;
    width: 100%;
    opacity: 0 !important;
  }
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 100%;
    z-index: 20;
  }
}

.icon__3d{
  width: 34px;
  height: 24px;
  min-width: 34px;
  margin-right: 24px;
  position: relative;
  z-index: 3;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.box__3d-actions{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  .btn__custom:before{
    opacity: 1;
  }

  .btn__custom{
    color: #fff !important;
  }

}

@media screen and (min-width: 1024px){
  .page-apartments{
    .sc__content-border.brd-indent-1:before {
      bottom: -386px;
    }
  }
}

/*.sc__panorama{
  & + .scroll__up {
    margin-left: 588px;
  }
}*/

.page-apartments{
  .footer{
    .callback__toggle {
      top: -72px;
    }
  }
}

.breadcrumbs{
  position: absolute;
  top: 102px;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  z-index: 30;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  li{
    text-transform: uppercase;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
    font-family: 'Garamond';
    color: #9D9D9C;
    a{
      color: #9D9D9C !important;
      &:hover{
        color: #A57C52 !important;
      }
    }
    &:not(:last-child){
      margin-right: 72px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        background: url("../img/bread-icon.svg") no-repeat;
        background-size: contain;
        width: 24px;
        height: 6px;
        top: 50%;
        left: 100%;
        margin-left: 24px;
        margin-top: -3px;
      }
    }
    &:last-child{
      color: #A57C52;
    }
  }
}

.sc__filter{
  background: #EFEFEF;
  .container{
    padding-top: 72px;
    padding-bottom: 80px;
  }
}

.sc__filter-start{
  .container{
    padding-bottom: 72px;
  }
}

.filter__label{
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
  sup{
    font-size: 11px;
  }
}

.checkbox__decor{
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 6px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 4px;
    left: 4px;
    border: 1px solid #B4B4B4;
  }
  &:after{
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: url("../img/checked.svg") no-repeat center;
    background-size: contain;
    opacity: 0;
  }
}

.checkbox__item{
  cursor: pointer;
  &:not(:last-child){
    margin-right: 24px;
  }
  &:hover{
    .checkbox__decor{
      &:before{
        border-color: #A57C52;
      }
    }
  }
  &.active{
    .checkbox__decor{
      &:before{
        opacity: 0;
      }
      &:after{
        opacity: 1;
      }
    }
  }
}

.checkbox__label{
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
}

.filter__col{
  margin-bottom: 72px;
}

.filter__form{
  .row{
    margin-bottom: -72px;
  }
}

.checkbox__label-small{
  font-size: 14px;
  line-height: 16.8px;
}

.checkbox__label-two{
  height: 16.8px;
}

.area__field{
  input{
    display: block;
    height: 42px;
    width: 48px;
    text-align: center;
    background: transparent;
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    border: none;
    border-bottom: 1px solid #B4B4B4;
    letter-spacing: 0.05em;
    color: #101820;
    &:focus{
      border-bottom: 1px solid #101820;
    }
  }
}

.field__sep{
  width: 16px;
  margin-right: 12px;
  margin-left: 12px;
  min-width: 16px;
  height: 1px;
  background-color: #4F4F4F;
}

.checkbox__vip-box{
  position: absolute;
  background: url("../img/vip-border.svg") no-repeat center;
  background-size: contain;
  width: 129px;
  height: 60px;
  left: 384px;
  bottom: 0;
}

.checkbox__item{
  position: relative;
  z-index: 3;
}

.checkbox__vip-title{
  position: absolute;
  width: 100%;
  left: 0;
  top: -16px;
  text-align: center;
  font-size: 24px;
  line-height: 100%;
  font-family: 'Garamond';
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.view__item-bl{
  height: 72px;
  cursor: pointer;
  border: 1px solid transparent;
  &.active{
    border: 1px solid #B4B4B4;
  }
}

.filter__nav-logo{
  width: 72px;
  margin: 0 auto 14.5px;
  img{
    display: block;
    width: 100%;
  }
}

.filter__nav-box{
  position: absolute;
  width: 96px;
  top: 50%;
  margin-top: -83px;
  display: block;
  left: -33px;
  z-index: 10;
}

.sc__filter-params{
  .filter__nav-box{
    position: absolute;
    width: 96px;
    top: 50%;
    margin-top: -83px;
    display: block;
    left: -33px;
    z-index: 10;
    &:hover{
      opacity: 1;
      .filter__nav-logo, .filter__nav-title{
        opacity: 0.75;
      }
    }
    &:before{
      content: '';
      left: 0;
      top: -48px;
      background: #EFEFEF;
      bottom: -48px;
      right: 0;
      position: absolute;
    }
    .filter__nav-logo, .filter__nav-title{
      position: relative;
      z-index: 3;
    }
  }
}

.sc__filter{
  .container{
    position: relative;
  }
}

.filter__nav-title{
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #A57C52;
}

.sc__results{
  .container{
    padding-top: 72px;
    padding-bottom: 90px;
  }
}

.app__item-card{
  padding: 48px 0;
  border: 1px solid #8B6E4B;
}

.app__item{
  &:not(:last-child){
    margin-bottom: 48px;
  }
}

.app__item-card-left{
  padding-left: 48px;
}

.app__item-card-type{
  margin-bottom: 52px;
  font-size: 34px;
  line-height: 120%;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-family: 'Garamond';
  position: relative;
  z-index: 2;
}

.app__item-data{
  margin-bottom: 52px;
}

.app__item-data-info{
  &:not(:last-child){
    margin-bottom: 6px;
  }
  font-family: 'Garamond';
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  sup{
    font-size: 60%;
    color: #8B6E4B;
  }
}

.app__item-card-left{
  height: 100%;
}

.app__item-card-building{
  width: 228px;
  img{
    width: 100%;
    display: block;
  }
}

.app__item-card-schema{
  height: 520px;
}

.app__item-card-schema{
  img{
    max-width: 100%;
    display: block;
    max-height: 100%;
  }
}

.app__item-card-actions{
  width: 144px;
  .btn__small{
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }
}

.btn__small{
  height: 32px;
  padding: 0 14px;
  border: 1px solid #101820;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: #101820 !important;
  &:hover{
    color: #fff !important;
    background-color: #101820;
  }
  &.btn__small-gr{
    border: none !important;
    background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
    color: #fff !important;
    &:hover{
      opacity: 0.75;
    }
  }
}

.app__info-descr{
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #101820;
}


.app__item-details-info{
  &:not(:last-child){
    margin-bottom: 8px;
  }
}

.app__item-details{
  margin-bottom: 20px;
}

.app__info-numb{
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #101820;
  width: 24px;
  margin-right: 24px;
  min-width: 24px;
}

.app__item-card-position-title{
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #8C9091;
  margin-bottom: 25px;
}

.app__item-card-right{
  height: 100%;
}

.app__item-card-position{
  padding-left: 48px;
}

.pager__wrap{
  height: 72px;
  width: 360px;
  cursor: pointer;
  margin: 48px auto 0;
  span{
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.3em;
    border-bottom: 1px solid #A57C52;
    text-transform: uppercase;
    color: #A57C52;
  }
  &:hover{
    span{
      border-bottom: 1px solid transparent;
    }
    .icon__loader{
      transform: rotate(180deg);
    }
  }
}

.icon__loader{
  width: 20px;
  min-width: 20px;
  height: 16px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
  margin-right: 12px;
  &.loading{
    -webkit-animation-name: spin;
    -webkit-animation-duration: 800ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 800ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 800ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.hidden{
  display: none !important;
}

.pager__wrap{
  margin-bottom: -28px;
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.sc__filter{
  position: relative;
  &.disabled{
    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 20;
    }
    .filter__form{
      opacity: 0.5;
    }
  }
}

.app__list-table{

  margin-right: -80px;
  margin-left: -80px;

  .app__item-card{
    display: none !important;
  }
  .app__item{
    border-bottom: 1px solid #B4B4B4;
    margin-bottom: 0;
  }
  position: relative;
  /*&:before{
    content: '';
    left: -72px;
    right: -72px;
    top: 15px;
    position: absolute;
    bottom: -192px;
    border: 1px solid #B4B4B4;
  }*/
}

.sorting__value{
  & > .d-flex{
    position: relative;
    &:before{
      content: '';
      left: -24px;
      right: -24px;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 3;
    }
  }
  span{
    position: relative;
    z-index: 4;
  }
}

.sort__toggle{
  cursor: pointer;
  &:hover, &.active{
    color: #A57C52 !important;
    path{
      stroke: #A57C52;
    }
  }
  &.desc{
    .icon__arrow{
      transform: rotateX(180deg);
    }
  }
}

.icon__arrow{
  width: 14px;
  height: 8px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.app__list-cards{
  .app__item-list{
    display: none !important;
  }
  .app__sorting-box{
    display: none !important;
  }
}

.app__item-data-info--top {
  margin-top: 22px;
}

.app__item-list-data{
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #101820;
  font-family: 'Garamond';
  padding-top: 51px;
  padding-bottom: 51px;
}
.app__item-area{
  letter-spacing: 0.05em;
  text-transform: none;
  sup{
    font-size: 50%;
  }
}
.app__item-rooms{
  letter-spacing: 0.05em;
  text-transform: none;
  margin-left: -30px;
  white-space: nowrap;
}

.app__item-floor{
  letter-spacing: 0.05em;
  text-transform: none;
  padding-left: 30px;
}

.app__sorting-box{
  margin-bottom: 56px;
}

.view-indent{
  .container{
    padding-top: 171px;
  }
}

.pager__wrap{
  position: relative;
  z-index: 3;
}

.icon__arrow{
  position: relative;
  z-index: 5;
  min-width: 14px;
  margin-left: 12px;
}

.sorting__item{
  text-transform: uppercase;
  font-family: 'Garamond';
  font-size: 18px;
  line-height: 30px;
  color: #8C9091;
  white-space: nowrap;
}

.sorting__value-rooms{
  margin-left: -30px;
}

.sorting__value-floor{
  padding-left: 30px;
}

.app__list-table.empty-data-mode:before {
  bottom: -100px;
}

.page-apartment .footer .callback__toggle {
  top: -47px;
}

.facade__item-schema-svg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.facade__item-schema-img{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
}

.schema__1{
  .st0{fill:#ECECEC;}
  .st1{fill:none;}
  .st2{fill:#E6E6E6;}
  .st3{fill:none;stroke:#ECECEC;stroke-miterlimit:10;}
  .st4{fill:none;stroke:#ECECEC;stroke-width:0.9978;stroke-miterlimit:10;}
  .st5{clip-path:url(#SVGID_2_);}
  .st6{fill:none;stroke:#ECECEC;stroke-width:0.75;stroke-miterlimit:10;}
  .st7{clip-path:url(#SVGID_4_);}
  .st8{fill:none;stroke:#ECECEC;stroke-width:0.5;stroke-miterlimit:10;}
  .st9{clip-path:url(#SVGID_6_);}

  .per__text-1{
    transform: rotate(
                    18deg
    );
    bottom: 37px;
    left: 93px;
  }

  .per__text-2{
    transform: rotate(
                    -14deg);
    bottom: 24px;
    right: 93px;
  }

  .facade__item-schema{

  }
}

[data-facade]{
  cursor: pointer;
  path{fill:transparent;}
  &:hover, &.active{
    path{fill:#A57C52;}
  }
}

.schema__6 [data-facade]{
  cursor: pointer;
  &:hover, &.active{
    .st3{fill:#A57C52;}
  }
}

.facade__item-bl{
  position: relative;
}

.per__text-1{
  width: 89px;
  height: 27px;
  position: absolute;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.per__text-2{
  width: 63px;
  height: 27px;
  position: absolute;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.per__text-1 ,.per__text-2{
  display: none !important;
}

.facade__numb-header{
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 19.79%, rgba(255, 255, 255, 0) 81.25%, #FFFFFF 100%), linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.36em;
  font-family: 'Garamond';
  margin-top: 16px;
}

.facade__res-item{
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #B4B4B4;
}

a.facade__res-item{
  position: relative;
  &:before{
    content: '';
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 19.79%, rgba(255, 255, 255, 0) 81.25%, #FFFFFF 100%), linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
  }
  &:hover, &.active{
    &:before{
      opacity: 0.25;
      visibility: visible;
    }
  }
}

.facade__res-col-1{
  width: 144px;
  min-width: 144px;
  font-size: 24px;
  line-height: 100%;
  font-family: 'Garamond';
  text-transform: uppercase;
  color: #101820;
  letter-spacing: 0.1em;
}

.facade__res-col-4{
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #A57C52;
  padding-top: 3px;
  width: 100%;
}

.facade__res-col-2{
  width: 120px;
  min-width: 120px;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #101820;
}

.facade__res-col-3{
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #101820;
  white-space: nowrap;
}

.facade__res-item{
  &:nth-child(1){
    .facade__res-col-1{
      letter-spacing: 0.02em;
      font-size: 34px;
      text-transform: none;
    }
  }
}

.filter__nav-box{
  &:hover{
    opacity: 0.75;
  }
}

.filter__facade-left{
  position: absolute;
  width: 96px;
  left: -33px;
  top: 204px;
  z-index: 3;
  &:before{
    content: '';
    left: 0;
    top: -48px;
    background: #fff;
    bottom: -48px;
    right: 0;

    position: absolute;
  }
  .filter__nav-box{
    position: relative;
    width: 100%;
    top: 0;
    margin-top: 0;
    display: block;
    left: 0;
  }

  .filter__nav-title{
    margin-right: -10px;
    margin-left: -10px;
  }
}

.sc__filter-facade{
  .container{
    position: relative;
  }
}

.filter__nav-filter-icon{
  width: 72px;
  height: 72px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.filter__compas{
  width: 92px;
  margin-top: 48px;
  transform: rotate(75deg);
  img{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.schema__2{
  .st0{fill:#ECECEC;}
  .st1{fill:#FFFFFF;}
  .st2{fill:#E6E6E6;}
  .st3{fill:#FFFFFF;stroke:#ECECEC;stroke-miterlimit:10;}
}

.facade__sl-box{
  .slick-current{
    z-index: 10;
  }
}

.schema__1 .facade__item-schema {
  margin-left: -68px;
  margin-right: -64px;
}


.schema__2 .facade__item-schema {
  margin-left: -67px;
  margin-right: -67px;
  margin-top: -21px;
}

.schema__3{
  .facade__item-schema{
    margin-left: -67px;
    margin-right: -67px;
  }
}

.schema__4{
  .st0{fill:#ECECEC;}
  .st1{fill:#E6E6E6;}
  .st2{fill:none;stroke:#ECECEC;stroke-width:0.75;stroke-miterlimit:10;}
  .st3{fill:none;stroke:#ECECEC;stroke-miterlimit:10;}
  .st4{fill:none;}

  .facade__item-schema{
    margin-left: -67px;
    margin-right: -67px;
  }
}

.schema__5{
  .st0{fill:#ECECEC;}
  .st1{fill:none;stroke:#ECECEC;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#ECECEC;stroke-width:0.75;stroke-miterlimit:10;}
  .st3{clip-path:url(#SVGID_2_);}
  .st4{fill:none;stroke:#ECECEC;stroke-width:0.5;stroke-miterlimit:10;}
  .st5{clip-path:url(#SVGID_4_);}
  .st6{fill:#E6E6E6;}
  .st7{fill:none;stroke:#ECECEC;stroke-width:2;stroke-miterlimit:10;}
  .st8{fill:none;}

  .facade__item-schema{
    margin-left: -67px;
    margin-right: -67px;
  }

  .per__text-2{
    left: 90px;
    transform: rotate(
                    20deg
    );
    bottom: 54px;
  }
}

.schema__2{
  .per__text-1 {
    right: 50px;
    transform: rotate(
                    -14deg
    );
    bottom: 34px;
  }
}

.page-select-facade .footer .callback__toggle {
  top: -46px;
}

.schema__3{
  .st0{fill:#FFFFFF;}
  .st1{fill:#ECECEC;}
  .st2{fill:#FFFFFF;stroke:#ECECEC;stroke-miterlimit:10;}
  .st3{fill:none;stroke:#ECECEC;stroke-width:0.5;stroke-miterlimit:10;}
  .st4{fill:#ECECEC;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
  .st5{fill:none;stroke:#ECECEC;stroke-miterlimit:10;}

  [data-facade]:hover, [data-facade].active{
    .st0{fill:#A57C52;}
    .st1{fill:#A57C52;}
  }
}

.facade__item-schema{
  display: flex;
  align-items: center;
  height: 447px;
  justify-content: center;
}

.schema__6{
  .st0{fill:#706F6F;}
  .st1{fill:#ECECEC;}
  .st2{fill:#111921;}
  .st3{fill:#ECECEC;}

  .facade__item-schema{
    margin-left: -67px;
    margin-right: -67px;
  }
}

.content__box-indent{
  padding-top: 48px;
}

.bank__list{
  padding-top: 24px;
  .row{
    margin-bottom: -24px;
  }
}

.bank__item{
  margin-bottom: 24px;
}

.bank__item-logo{
  //cursor: pointer;
  img{
    display: block;
    width: 100%;
  }
}

.bank__list {
  margin-bottom: -54px;
}

.text{
  a{
    color: #A57C52 !important;
    border-bottom: 1px solid #A57C52;
    &:hover{
      border-bottom: 1px solid transparent;
    }
  }
}

.static__img{
  margin: 0 -252px;
  img{
    display: block;
    width: 100%;
  }
}

.page-pent{
  .footer{
    .callback__toggle{
      display: none !important;
    }
  }
}


.sc__pent-promo{
  position: relative;
  .container{
    padding-bottom: 0 !important;
  }
  .callback__toggle{
    position: absolute;
    left: 50%;
    bottom: -48px;
    margin-left: -612px;
  }
}

.sc__screen{
  &:not(:last-child){
    margin-bottom: 72px;
  }
}

.content__sl-row{
  margin-left: -36px;
  margin-right: -36px;
}

.content__sl-item{
  padding-right: 36px;
  padding-left: 36px;
}

.content__sl-item-img{
  img{
    display: block;
    width: 100%;
  }
}

.content__sl{
  font-size: 0;
  .slick-list{
    overflow: visible;
  }
}

.content__box-descr{
  padding: 36px;
  color: #fff;
  background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
  margin-top: 36px;
}

.content__sl-box{
  position: relative;
}

.content__sl-nav-box{
  position: absolute;
  background-color: #fff;
  padding: 36px 0 0 36px;
  z-index: 2;
  bottom: 0;
  right: 0;
}

.content__sl-info{
  height: 72px;
  width: 83px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
}

.sc__screen-indent{
  padding-top: 36px;
}

.text{
  & > *:last-child{
    margin-bottom: 0 !important;
  }
}

.app__item-data-label{
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.1em;
  font-family: 'Garamond';
}

.app__item-data-hint{
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  margin-top: 4px;
  font-family: 'Garamond';
}

.app__item-card-pent{
  .app__item-data-info:not(:last-child) {
    margin-bottom: 12px;
  }
  .app__item-details-info:not(:last-child) {
    margin-bottom: 3px;
  }
  .app__item-card-schema {
    width: 100%;
    padding-bottom: 20px;
    align-items: flex-end !important;
  }
  .app__item-card-right-header{
    padding-left: 240px;
    width: 100%;
  }

  .pent__compas{
    width: 72px;
    min-width: 72px;
    transform: rotate(75deg);
    img{
      display: block;
      width: 100%;
    }
  }
}

.app__card-box{
  &:not(:last-child){
    margin-bottom: 36px;
  }
  .app__item-card-type{
    margin-bottom: 0;
  }
}

.app__item-data-hint{
  &:first-child{
    margin-top: 0 !important;
  }
}

.app__item-card-right-header{
  margin-bottom: 24px;
  .app__item-data-info{
    font-size: 34px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }
  .app__item-data-label{
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.25em;
  }
}

.pent__list{
  .app__item-card{
    &:not(:last-child){
      margin-bottom: 48px;
    }
  }
}

.page-about{
  .sc__screen{
    &:not(:last-child){
      margin-bottom: 0;
    }
    &:not(:first-child){
      &.sc__about-history{
        .container{
          padding-top: 48px;
        }
      }
      .container{
        padding-top: 72px;
      }
    }
  }
}

.content__img{
  img{
    display: block;
    width: 100%;
  }
}

.quote__item{
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #333333;
  padding-top: 64px;
  padding-bottom: 64px;
  position: relative;
  font-family: 'Garamond';
  &:before, &:after{
    content: '';
    height: 1px;
    position: absolute;
    background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
    top: 0;
    left: 0;
    width: 100%;
  }
  &:after{
    top: auto;
    bottom: 0;
  }
}

.content__img-right{
  padding-left: 48px;
  margin-top: -100px;
  padding-top: 48px;
  padding-right: 48px;
  background: #fff;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: 24px;
  }
  .quote__item{
    margin-top: 72px;
  }
}

.chess__item-img{
  img{
    display: block;
    width: 100%;
  }
}

.chess__item-ct-box{
  padding: 48px;
}

.chess__item{
  &:nth-child(2n + 1){
    .row{
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
  }

  &:not(:last-child){
    margin-bottom: 8px;
  }
}

.sc__header-text{
  text-align: center;
  font-family: 'Garamond';
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  margin-top: 24px;
}

.page-about{
  .content__box-indent {
    padding-top: 36px;
  }
}

.btn__hint{
  margin-top: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #828282;
}

.chess__item-title{
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-family: 'Garamond';
}

.sc__screen{
  & + .sc__dark{
    margin-top: 60px;
    margin-bottom: 24px !important;
  }
}

.sc__dark{
  background: #EFEFEF;
}


.sc__about-eng, .sc__about-safety{
  .chess__item{
    &:not(:last-child){
      margin-bottom: 70px;
    }
  }
}

.services__item-icon{
  width: 48px;
  height: 48px;
  margin: 0 auto 12px;
  img{
    display: block;
    width: 100%;
  }
}

.services__item{
  margin-bottom: 48px;
  &:nth-child(3n + 2), &:last-child{
    .services__item-bl{
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:nth-child(3n + 3){
    .services__item-bl{
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.services__list{
  .row{
    margin-bottom: -48px;
  }
}

.services__item-bl{
  max-width: 264px;
}

#services, #engineering, #safety{
  .container{
    padding-bottom: 72px;
  }
}

.comfort__item-bl{
  height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 50%);
  }
}

.comfort__item-ct{
  position: absolute;
  padding: 36px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.comfort__item-icon{
  width: 72px;
  min-width: 72px;
  height: 72px;
  margin-right: 12px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.comfort__item-descr{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.comfort__item-1, .comfort__item-2, .comfort__item-4, .comfort__item-5, .comfort__item-9, .comfort__item-10, .comfort__item-11, .comfort__item-12{
  .comfort__item-bl{
    padding-top: 83.3333%;
  }
}

.comfort__item-3, .comfort__item-6, .comfort__item-7{
  .comfort__item-bl{
    padding-top: 41.66666667%;
  }
}

.comfort__item-8{
  .comfort__item-bl{
    padding-top: 166.66666667%;
  }
}

.inside__nav{
  /*position: absolute;*/
  position: fixed;
  top: 102px;
  left: 50%;
  margin-left: -470px;
  z-index: 30;
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      display: inline-block;
      vertical-align: top;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-family: 'Garamond';
      color: #101820;
      &.active{
        a{
          color: #A57C52 !important;
          cursor: default;
        }
      }
      a{
        cursor: pointer;
        color: #101820 !important;
        &:hover{
          color: #A57C52 !important;
        }
      }
      &:not(:last-child){
        margin-right: 13px;
      }
    }
  }
}

.inside__schema-img {
  img {
    display: block;
    width: 100%;
  }
}

.sc__about-history, .sc__about-arch{
  position: relative;
  .scroll__up{
    margin-left: 588px;
    position: fixed;
    left: 50%;
    bottom: 0;
    z-index: 1;
    &.abs{
      position: absolute;
      bottom: auto;
      top: 150px;
    }
  }
}

.content__sl-box, .comfort__list{
  z-index: 2;
  position: relative;
}

.comfort__item-icon{
  img{
    display: block;
    width: 100%;
  }
}

.years__tabs{
  margin-bottom: 48px;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.years__tab{
  cursor: pointer;
  &:hover{
    opacity: 0.75;
  }
  &:not(:last-child){
    margin-left: 72px;
  }
  font-size: 34px;
  font-family: 'Garamond';
  line-height: 42px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.sc__progress{
  .container{
    padding-top: 48px;
  }
}

.years__month{
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.05em;
  font-family: 'Garamond';
  cursor: pointer;
  text-transform: uppercase;
  color: #101820;
  opacity: 0.5;
  &:hover{
    opacity: 1;
  }
  &.active{
    color: #A57C52 !important;
  }
  &:not(:last-child){
    margin-right: 27px;
  }
}

.years__tabs-ct{
  margin-bottom: 72px;
}

.years__months{
  margin-bottom: 48px;
}

.u-mb--30 {
  margin-bottom: 30px;
}

.progress__sl-item-img{
  img{
    display: block;
    width: 100%;
    max-height: 419px;
    object-fit: contain;
  }
}

.u-mb--10 {
  margin-bottom: 10px;
}

.pent-new--1 {
  .app__item-card-schema  {
    img {
      max-width: 614px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 27px;

      @media screen and (max-width: 1023px){
        margin-top: 0;
      }
    }
  }

  .pent__slide--2 {
    .app__item-card-schema {
      margin-top: -30px !important;
      margin-bottom: 44px !important;

      @media screen and (max-width: 1023px){
        margin-top: 0 !important;
        margin-bottom: 34px !important;
      }
    }
  }
}


.pr__actions{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.pr__sl-date{
  height: 60px;
  padding: 0 30px;
  background: #EFEFEF;
  font-size: 16px;
  line-height: 24px;
  color: #101820;
  letter-spacing: 0.05em;
}

.progress__sl-item-img{
  position: relative;
}

.progress__sl-row{
  margin-right: -36px;
  margin-left: -36px;
}

.progress__sl-item{
  padding-right: 36px;
  padding-left: 36px;
}

.progress__sl{
  .slick-list{
    overflow: visible;
  }
  position: relative;
  &:before{
    content: '';
    top: 0;
    bottom: 0;
    width: 2000px;
    right: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    z-index: 2;
  }
  &:after{
    content: '';
    top: 0;
    bottom: 0;
    width: 2000px;
    left: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    z-index: 2;
  }
}

.progress__sl-item-descr{
  margin-top: 48px;
  display: none !important;
}

.slick-current{
  .progress__sl-item-descr{
    display: block !important;
  }
}

.progress__sl-box{
  position: relative;
  .nav__sl-arrow {
    position: absolute;
    top: 210px;
    margin-top: -36px;
    z-index: 5;
  }
  .nav__sl-arrow-left{
    right: 50%;
    margin-right: 640px;
  }

  .nav__sl-arrow-right{
    left: 50%;
    margin-left: 640px;
  }
}

.inside__schema{
  position: relative;
}

.inside__schema-data{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.infra__info-item{
  &:not(:last-child){
    margin-bottom: 25px;
  }
}

.infra__info-item-bl{
  cursor: pointer;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    top: 100%;
    margin-top: 5px;
    background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
    opacity: 0;
  }
}

.infra__info-item-icon{
  width: 48px;
  min-width: 48px;
  margin-right: 12px;
}

.infra__info-list{
  padding-left: 25px;
}

.infra__info-item-title{
  color: #101820;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.modal__custom{
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 300;
  left: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  font-size: 0;
  overflow-y: auto;
  overflow-x: hidden;
  &[data-show="true"]{
    opacity: 1;
    visibility: visible;
    .modal__box{
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }
  }
  &:before{
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: -0.35em;
    height: 100%;
  }
}

.modal__box{
  width: 912px;
  text-align: left;
  padding: 72px;
  background: #fff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateY(-4rem);
  -moz-transform: translateY(-4rem);
  -ms-transform: translateY(-4rem);
  -o-transform: translateY(-4rem);
  transform: translateY(-4rem);
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.modal__close{
  width: 72px;
  height: 72px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  &:hover{
    path{
      stroke: #101820;
    }
  }
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.modal__infra-box{
  .row__custom{
    margin-right: -24px;
    margin-left: -24px;
  }
  .col__custom{
    padding-left: 24px;
    padding-right: 24px;
  }
}

.modal__infra-img{
  img{
    display: block;
    width: 100%;
  }
}

.modal__infra-icon{
  margin-bottom: 24px;
  .icon__custom{
    &:after{
      opacity: 1;
    }
    &:before{
      opacity: 0;
    }
  }
}

.modal__infra-title{
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Garamond';
  letter-spacing: 0.02em;
}

.modal__infra-descr{
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
}

.sc__infra-inside{
  .container{
    padding-bottom: 118px;
  }
}

.infra__info-item{
  &.inactive{
    opacity: 0.4;
  }
  &.active{
    .infra__info-item-bl{
      &:after{
        opacity: 1;
      }
    }
    .icon__custom{
      &:before{
        opacity: 0;
      }
      &:after{
        opacity: 1;
      }
    }
  }
}

.page:not(.page-ie){
  .infra__info-item{
    &.active{
      .infra__info-item-title{
        background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.icon_pin{
  cursor: pointer;
  &.icon_pin-hide{
    opacity: 0;
  }
}

.brad__nav{
  position: absolute;
  left: 50%;
  margin-left: -350px;
  width: 700px;
  top: 93px;
  z-index: 20;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    text-align: center;
    li{
      display: inline-block;
      vertical-align: top;
      &:not(:last-child){
        margin-right: 48px;
      }
      font-family: 'Garamond';
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: #101820;
      padding-bottom: 14px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
        left: 0;
        height: 2px;
        width: 100%;
        opacity: 0;
      }
      a{
        color: #101820 !important;
      }
      &.active{
        &:after{
          opacity: 1;
        }
      }
    }
  }
}

.page:not(.page-ie){
  .brad__nav{
    ul{
      li{
        &.active{
          a{
            background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        a:hover{
          background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.infra__info-list-outside{
  padding-left: 0 !important;
  margin-top: 24px;
  .infra__info-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .infra__info-item-icon{
    width: 36px;
    height: 36px;
    min-width: 36px;
    margin-right: 24px;
  }
  .icon__custom {
    width: 36px;
    height: 36px;
  }
}

.icon__park{
  &:before{
    background-image: url("../img/infra/icon-park.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-park-gr.svg");
  }
}

.icon__theatre{
  &:before{
    background-image: url("../img/infra/icon-theatre.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-theatre-gr.svg");
  }
}

.icon__mus{
  &:before{
    background-image: url("../img/infra/icon-museum.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-mus-gr.svg");
  }
}

.icon__rest2{
  &:before{
    background-image: url("../img/infra/icon-rest2.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-rest2-gr.svg");
  }
}

.icon__sport{
  &:before{
    background-image: url("../img/infra/icon-sport.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-sport-gr.svg");
  }
}

.icon__moll{
  &:before{
    background-image: url("../img/infra/icon-moll.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-moll-gr.svg");
  }
}

.icon__educ{
  &:before{
    background-image: url("../img/infra/icon-educ.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-educ-gr.svg");
  }
}

.icon__med{
  &:before{
    background-image: url("../img/infra/icon-med.svg");
  }
  &:after{
    background-image: url("../img/infra/icon-med-gr.svg");
  }
}

.out-area{
  cursor: pointer;
}

.outside__schema-img{
  position: relative;
}

.map__pin{
  width: 32px;
  height: 36px;
  background: url("../img/pin2.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  &.active{
    opacity: 1;
    visibility: visible;
  }
}

.map__pin-1{
  top: 330px;
  left: 523px;
}

.map__pin-1-1{
  top: 305px;
  left: 447px;
}

.map__pin-1-2{
  top: 534px;
  left: 447px;
}

.map__pin-1-3{
  top: 511px;
  left: 486px;
}

.map__pin-1-4{
  top: 226px;
  left: 117px;
}

.map__pin-1-5{
  top: 165px;
  left: 301px;
}

.map__pin-1-6{
  top: 599px;
  left: 386px;
}

.map__pin-1-7{
  top: 599px;
  left: 186px;
}

.map__pin-1-8{
  top: 382px;
  left: 560px;
}

.map__pin-1-9{
  top: 46px;
  left: 498px;
}

.map__pin-1-10{
  top: 268px;
  left: 159px;
}

.map__pin-1-11{
  top: 328px;
  left: 116px;
}

.map__pin-2-1{
  top: -9px;
  left: 426px;
}

.map__pin-2-2{
  top: 182px;
  left: 393px;
}

.map__pin-2-3{
  top: 410px;
  left: 78px;
}

.map__pin-2-4{
  top: 425px;
  left: 248px;
}

.map__pin-2-5{
  top: 346px;
  left: 625px;
}

.map__pin-2-6{
  top: 286px;
  left: 666px;
}

.map__pin-2-7{
  top: 146px;
  left: 406px;
}

.map__pin-2-8{
  top: 471px;
  left: 290px;
}

.map__pin-2-9{
  top: 360px;
  left: 483px;
}

.map__pin-3-3{
  top: 558px;
  left: 196px;
}

.map__pin-3-1{
  top: 491px;
  left: 395px;
}

.map__pin-3-2{
  top: 654px;
  left: 91px;
}

.map__pin-4-1{
  top: 328px;
  left: 310px;
}

.map__pin-4-2{
  top: 273px;
  left: 439px;
}

.map__pin-4-3{
  top: 230px;
  left: 449px;
}

.map__pin-4-4{
  top: 319px;
  left: 411px;
}

.map__pin-4-5{
  top: 187px;
  left: 463px;
}

.map__pin-4-6{
  top: 145px;
  left: 475px;
}

.map__pin-4-7{
  top: 79px;
  left: 605px;
}

.map__pin-5-1{
  top: 442px;
  left: 642px;
}

.map__pin-5-2{
  top: 218px;
  left: 585px;
}

.map__pin-5-3{
  top: 38px;
  left: 20px;
}

.map__pin-6-1{
  top: 422px;
  left: 296px;
}

.map__pin-6-2{
  top: 492px;
  left: 336px;
}

.map__pin-6-3{
  top: 448px;
  left: 349px;
}

.map__pin-6-4{
  top: 299px;
  left: 340px;
}

.map__pin-6-5{
  top: 404px;
  left: 249px;
}

.map__pin-6-6{
  top: 419px;
  left: 362px;
}

.map__pin-6-7{
  top: 140px;
  left: 268px;
}


.map__pin-7-1{
  top: 81px;
  left: 485px;
}

.map__pin-7-2{
  top: 166px;
  left: 470px;
}

.map__pin-7-3{
  top: 191px;
  left: 538px;
}

.map__pin-7-4{
  top: 451px;
  left: 513px;
}

.map__pin-7-5{
  top: 415px;
  left: 445px;
}

.map__pin-7-6{
  top: 397px;
  left: 354px;
}

.map__pin-7-7{
  top: 356px;
  left: 609px;
}

.map__pin-7-8{
  top: 392px;
  left: 410px;
}

.map__pin-8{
  top: 4px;
  left: 572px;
}

.map__pin-7-9{
  top: 359px;
  left: 485px;
}

.map__pin-7-10{
  top: 311px;
  left: 54px;
}

.map__pin-7-11{
  top: 217px;
  left: 505px;
}

.map__pin-81{
  top: 354px;
  left: 460px;
}

.map__pin-82{
  top: 387px;
  left: 342px;
}


.map__pin-text{
  position: absolute;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #A57C52;
  top: 50%;
  margin-top: -10px;
  left: 100%;
  margin-left: 8px;
  white-space: nowrap;
}

.modal__form{
  .modal__box{
    width: 552px;
    padding: 96px 90px;
  }
}

.modal__header{
  margin-bottom: 48px;
}

.modal__title{
  text-align: center;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Garamond';
}

.form__label{
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 2px;
  letter-spacing: 0.05em;
  color: #8C9091;
}

.form__field{
  input, textarea{
    display: block;
    height: 32px;
    border: none;
    width: 100%;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #101820;
    border-bottom: 1px solid #B4B4B4;
    background: transparent;
    &[disabled]{
      opacity: 0.65;
    }
  }

  textarea{
    resize: none;
    height: 64px;
    padding-top: 8px;
  }
}

.form__field-name{
  position: relative;
  &:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url("../img/form/icon-user.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    right: 0;
    position: absolute;
  }
}

.form__field-phone{
  position: relative;
  &:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url("../img/form/icon-phone.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    right: 0;
    position: absolute;
  }
}

.form__field-email{
  position: relative;
  &:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url("../img/form/icon-email.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    right: 0;
    position: absolute;
  }
}

.form__field-date{
  position: relative;
  &:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url("../img/form/icon-date.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    right: 0;
    position: absolute;
  }
}

.hd__item{
  .form__after{
    margin-top: 0;
  }
}

.form__field-time{
  position: relative;
  &:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url("../img/form/icon-time.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    right: 0;
    position: absolute;
  }
}

.form__field-password{
  position: relative;
  &:after{
    content: '';
    width: 12px;
    height: 16px;
    background: url("../img/form/icon-pass.svg") no-repeat center;
    background-size: contain;
    top: 50%;
    margin-top: -8px;
    right: 0;
    position: absolute;
  }
}

.form__item{
  &:not(:last-child){
    margin-bottom: 24px;
  }
}

.form__fields{
  margin-bottom: 48px;
}

.modal__subtitle{
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #101820;
}

.modal__text{
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #101820;
  p{
    margin: 0 0 24px 0;
  }
  & > *:last-child{
    margin-bottom: 0 !important;
  }
  span{
    color: #A57C52;
  }
}


.modal__custom--recovery{
  .modal__title{
    letter-spacing: 0.12em;
  }
}

.modal__title{
  &:not(:last-child){
    margin-bottom: 24px;
  }
}

.sc__about-comfort{
  .container{
    padding-bottom: 72px;
  }
}

.form__action{
  position: relative;
  &.disabled{
    .btn__custom, .toggle__subscribe-bl{
      opacity: 0.35;
    }
    &:before{
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
      z-index: 4;
    }
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      background: url("../img/loader.svg") no-repeat center;
      background-size: contain;
      width: 64px;
      height: 64px;
      z-index: 5;
      top: 50%;
      margin-left: -32px;
      margin-top: -32px;
    }

  }
}

.form__field{
  &.has-error{
    input, textarea {
      border-color: #DD0B14;
    }
  }
}

.error__message{
  color: #DD0B14;
  font-size: 11px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
}

.modal__info{
  .modal__header{
    margin-bottom: 0;
  }

  .modal__subtitle{
    font-size: 16px;
    line-height: 24px;
  }
}

.facade__sl-box, .floor__schema{
  &:not(.active){
    display: none;
  }
}

.floor__schema-1-2-5{
  .st0{fill:#707072;}
  .st1{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1346,2.1346;}
  .st3{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.0827,4.0827;}
  .st4{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
  .st5{fill-rule:evenodd;clip-rule:evenodd;fill:#707072;}
  .st6{fill:#F6F6F6;}
  .st7{fill:#8C704D;}
  .st8{fill:#71706F;}
  .st9{fill:#757175;}
  .st10{fill:#FFFFFF;}
  .st11{fill:#E8E0D9;}
  .st12{fill:#6C6B6F;}
}

.floor__schema-2-2-5{
  .st0{fill:#707072;}
  .st1{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1346,2.1346;}
  .st3{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.0827,4.0827;}
  .st4{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
  .st5{fill-rule:evenodd;clip-rule:evenodd;fill:#707072;}
  .st6{fill:#F6F6F6;}
  .st7{fill:#71706F;}
  .st8{fill:#8C704D;}
  .st9{fill:#757175;}
  .st10{fill:#FFFFFF;}
  .st11{fill:#E8E0D9;}
  .st12{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
}

.floor__schema-3-2-5{
  .st0{fill:#707072;}
  .st1{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1567,2.1567;}
  .st3{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.1924,4.1924;}
  .st4{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
  .st5{fill:none;stroke:#707072;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;}
  .st6{fill:#F6F6F6;}
  .st7{fill:#71706F;}
  .st8{fill:#8C704D;}
  .st9{fill:#757175;}
  .st10{fill:#706F6E;}
  .st11{fill:#FFFFFF;}
  .st12{fill:#E8E0D9;}
  .st13{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;}
  .st14{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-miterlimit:10;}
  .st15{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-linejoin:round;}
  .st16{fill:none;stroke:#6C6B6F;stroke-width:0.5;stroke-miterlimit:10;}
  .st17{fill:none;stroke:#686869;stroke-width:0.5;stroke-miterlimit:10;}
}

.floor__schema-4-2-5{
  .st0{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-linejoin:round;stroke-miterlimit:10;}
  .st1{fill:none;stroke:#71706F;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-miterlimit:10;}
  .st3{fill:none;stroke:#71706F;stroke-width:0.5;stroke-miterlimit:10;}
  .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#707072;}
  .st5{fill:#707072;}
  .st6{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;}
  .st7{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1346,2.1346;}
  .st8{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.0827,4.0827;}
  .st9{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
  .st10{fill:#F6F6F6;}
  .st11{fill:#71706F;}
  .st12{fill:#8C704D;}
  .st13{fill:#757175;}
  .st14{fill:#FFFFFF;}
  .st15{fill:#E8E0D9;}
  .st16{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-linejoin:round;}
  .st17{fill:none;stroke:#707072;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;}
  .st18{fill:none;stroke:#707072;stroke-width:0.25;stroke-miterlimit:10;}
}

.floor__schema-5-2-5{
  .st0{fill:#707072;}
  .st1{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1346,2.1346;}
  .st3{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.0827,4.0827;}
  .st4{fill:none;stroke:#707072;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
  .st5{fill-rule:evenodd;clip-rule:evenodd;fill:#707072;}
  .st6{fill:#F6F6F6;}
  .st7{fill:#71706F;}
  .st8{fill:#8C704D;}
  .st9{fill:#757175;}
  .st10{fill:#FFFFFF;}
  .st11{fill:#E8E0D9;}
  .st12{fill:none;}
  .st13{fill:none;stroke:#707072;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;}
  .st14{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-linejoin:round;}
  .st15{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-miterlimit:10;}
  .st16{fill:none;stroke:#6C6B6F;stroke-width:0.5;stroke-miterlimit:10;}
  .st17{fill:none;stroke:#8C704D;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;}
}

.floor__schema-1-6{
  .st0{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-linejoin:round;stroke-miterlimit:10;}
  .st1{fill:none;stroke:#6E6E6E;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-miterlimit:10;}
  .st3{fill:none;stroke:#6E6E6E;stroke-width:0.5;stroke-miterlimit:10;}
  .st4{fill:#F6F6F6;}
  .st5{clip-path:url(#SVGID_2_);}
  .st6{clip-path:url(#SVGID_6_);}
  .st7{clip-path:url(#SVGID_10_);}
  .st8{clip-path:url(#SVGID_14_);}
  .st9{clip-path:url(#SVGID_18_);}
  .st10{clip-path:url(#SVGID_22_);}
  .st11{clip-path:url(#SVGID_26_);}
  .st12{clip-path:url(#SVGID_30_);}
  .st13{clip-path:url(#SVGID_34_);}
  .st14{fill:#6E6E6E;}
  .st15{clip-path:url(#SVGID_38_);}
  .st16{clip-path:url(#SVGID_42_);}
  .st17{clip-path:url(#SVGID_46_);}
  .st18{clip-path:url(#SVGID_50_);}
  .st19{clip-path:url(#SVGID_54_);}
  .st20{clip-path:url(#SVGID_58_);}
  .st21{fill:none;stroke:#6E6E6E;stroke-width:0.5;stroke-miterlimit:10.0003;}
  .st22{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-linejoin:round;stroke-miterlimit:9.9992;}
  .st23{clip-path:url(#SVGID_62_);}
  .st24{clip-path:url(#SVGID_66_);}
  .st25{clip-path:url(#SVGID_70_);}
  .st26{clip-path:url(#SVGID_74_);}
  .st27{clip-path:url(#SVGID_78_);}
  .st28{clip-path:url(#SVGID_82_);}
  .st29{clip-path:url(#SVGID_86_);}
  .st30{clip-path:url(#SVGID_90_);}
  .st31{clip-path:url(#SVGID_94_);}
  .st32{clip-path:url(#SVGID_98_);}
  .st33{clip-path:url(#SVGID_102_);}
  .st34{clip-path:url(#SVGID_106_);}
  .st35{clip-path:url(#SVGID_110_);}
  .st36{clip-path:url(#SVGID_114_);}
  .st37{clip-path:url(#SVGID_118_);}
  .st38{clip-path:url(#SVGID_122_);}
  .st39{clip-path:url(#SVGID_126_);}
  .st40{clip-path:url(#SVGID_130_);}
  .st41{clip-path:url(#SVGID_134_);}
  .st42{clip-path:url(#SVGID_138_);}
  .st43{clip-path:url(#SVGID_142_);}
  .st44{clip-path:url(#SVGID_146_);}
  .st45{clip-path:url(#SVGID_150_);}
  .st46{clip-path:url(#SVGID_154_);}
  .st47{clip-path:url(#SVGID_158_);}
  .st48{clip-path:url(#SVGID_162_);}
  .st49{clip-path:url(#SVGID_166_);}
  .st50{clip-path:url(#SVGID_170_);}
  .st51{clip-path:url(#SVGID_174_);}
  .st52{clip-path:url(#SVGID_178_);}
  .st53{clip-path:url(#SVGID_182_);}
  .st54{clip-path:url(#SVGID_186_);}
  .st55{clip-path:url(#SVGID_190_);}
  .st56{clip-path:url(#SVGID_194_);}
  .st57{clip-path:url(#SVGID_198_);}
  .st58{clip-path:url(#SVGID_202_);}
  .st59{clip-path:url(#SVGID_206_);}
  .st60{clip-path:url(#SVGID_210_);}
  .st61{clip-path:url(#SVGID_214_);}
  .st62{clip-path:url(#SVGID_218_);}
  .st63{clip-path:url(#SVGID_222_);}
  .st64{clip-path:url(#SVGID_226_);}
  .st65{clip-path:url(#SVGID_230_);}
  .st66{clip-path:url(#SVGID_234_);}
  .st67{clip-path:url(#SVGID_238_);}
  .st68{clip-path:url(#SVGID_242_);}
  .st69{clip-path:url(#SVGID_246_);}
  .st70{clip-path:url(#SVGID_250_);}
  .st71{clip-path:url(#SVGID_254_);}
  .st72{clip-path:url(#SVGID_258_);}
  .st73{clip-path:url(#SVGID_262_);}
  .st74{clip-path:url(#SVGID_266_);}
  .st75{clip-path:url(#SVGID_270_);}
  .st76{clip-path:url(#SVGID_274_);}
  .st77{clip-path:url(#SVGID_278_);}
  .st78{fill:#6E6F72;}
  .st79{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;}
  .st80{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1346,2.1346;}
  .st81{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.0827,4.0827;}
  .st82{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
}

.floor__schema-2-6{
  .st0{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-linejoin:round;stroke-miterlimit:10;}
  .st1{fill:none;stroke:#6E6E6E;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st2{fill:none;stroke:#8A6E4B;stroke-width:0.5;stroke-miterlimit:10;}
  .st3{fill:#F6F6F6;}
  .st4{fill:none;stroke:#6E6E6E;stroke-width:0.5;stroke-miterlimit:10;}
  .st5{fill:#6E6E6E;}
  .st6{clip-path:url(#SVGID_2_);}
  .st7{clip-path:url(#SVGID_6_);}
  .st8{clip-path:url(#SVGID_10_);}
  .st9{clip-path:url(#SVGID_14_);}
  .st10{clip-path:url(#SVGID_18_);}
  .st11{clip-path:url(#SVGID_22_);}
  .st12{clip-path:url(#SVGID_26_);}
  .st13{clip-path:url(#SVGID_30_);}
  .st14{clip-path:url(#SVGID_34_);}
  .st15{clip-path:url(#SVGID_38_);}
  .st16{clip-path:url(#SVGID_42_);}
  .st17{clip-path:url(#SVGID_46_);}
  .st18{clip-path:url(#SVGID_50_);}
  .st19{clip-path:url(#SVGID_54_);}
  .st20{clip-path:url(#SVGID_58_);}
  .st21{clip-path:url(#SVGID_62_);}
  .st22{fill:none;stroke:#45413F;stroke-width:1.899248e-03;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  .st23{clip-path:url(#SVGID_66_);}
  .st24{clip-path:url(#SVGID_70_);}
  .st25{clip-path:url(#SVGID_74_);}
  .st26{clip-path:url(#SVGID_78_);}
  .st27{clip-path:url(#SVGID_82_);}
  .st28{clip-path:url(#SVGID_86_);}
  .st29{clip-path:url(#SVGID_90_);}
  .st30{fill:none;stroke:#6E6E6E;stroke-width:0.5;stroke-miterlimit:10.0004;}
  .st31{clip-path:url(#SVGID_94_);}
  .st32{clip-path:url(#SVGID_98_);}
  .st33{clip-path:url(#SVGID_102_);}
  .st34{clip-path:url(#SVGID_106_);}
  .st35{clip-path:url(#SVGID_110_);}
  .st36{clip-path:url(#SVGID_114_);}
  .st37{clip-path:url(#SVGID_118_);}
  .st38{clip-path:url(#SVGID_122_);}
  .st39{clip-path:url(#SVGID_126_);}
  .st40{clip-path:url(#SVGID_130_);}
  .st41{clip-path:url(#SVGID_134_);}
  .st42{clip-path:url(#SVGID_138_);}
  .st43{clip-path:url(#SVGID_142_);}
  .st44{clip-path:url(#SVGID_146_);}
  .st45{clip-path:url(#SVGID_150_);}
  .st46{clip-path:url(#SVGID_154_);}
  .st47{clip-path:url(#SVGID_158_);}
  .st48{clip-path:url(#SVGID_162_);}
  .st49{clip-path:url(#SVGID_166_);}
  .st50{clip-path:url(#SVGID_170_);}
  .st51{clip-path:url(#SVGID_174_);}
  .st52{clip-path:url(#SVGID_178_);}
  .st53{clip-path:url(#SVGID_182_);}
  .st54{fill:none;stroke:#6E6E6E;stroke-miterlimit:10;}
  .st55{clip-path:url(#SVGID_186_);}
  .st56{clip-path:url(#SVGID_190_);}
  .st57{clip-path:url(#SVGID_194_);}
  .st58{clip-path:url(#SVGID_198_);}
  .st59{clip-path:url(#SVGID_202_);}
  .st60{clip-path:url(#SVGID_206_);}
  .st61{clip-path:url(#SVGID_210_);}
  .st62{clip-path:url(#SVGID_214_);}
  .st63{clip-path:url(#SVGID_218_);}
  .st64{clip-path:url(#SVGID_222_);}
  .st65{clip-path:url(#SVGID_226_);}
  .st66{clip-path:url(#SVGID_230_);}
  .st67{clip-path:url(#SVGID_234_);}
  .st68{clip-path:url(#SVGID_238_);}
  .st69{clip-path:url(#SVGID_242_);}
  .st70{clip-path:url(#SVGID_246_);}
  .st71{clip-path:url(#SVGID_250_);}
  .st72{clip-path:url(#SVGID_254_);}
  .st73{clip-path:url(#SVGID_258_);}
  .st74{clip-path:url(#SVGID_262_);}
  .st75{clip-path:url(#SVGID_266_);}
  .st76{clip-path:url(#SVGID_270_);}
  .st77{fill:#6E6F72;}
  .st78{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;}
  .st79{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:2.1346,2.1346;}
  .st80{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4.0827,4.0827;}
  .st81{fill:none;stroke:#6E6F72;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:4,4;}
  .st82{fill-rule:evenodd;clip-rule:evenodd;fill:#6E6F72;}
}

.floor__schema{
  padding-right: 50px;
  position: relative;
  z-index: 3;

  svg{
    width: 100%;
    display: block;
    height: auto;
  }
}

.active-pol{
  fill: transparent;
}

[data-schema]{
  cursor: pointer;
  &.active{
    .active-pol{
      fill: rgba(189, 158, 125, 0.85);
    }
  }
}

.floor__schema-list{
  &:not(.active){
    display: none !important;
  }
}

.floor__schema-list{
  position: relative;
}

.floor__schema-img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-right: 50px;
  img{
    display: block;
    width: 100%;
  }
}

.app__item-card-pent{
  .pent__compas {
    position: absolute;
    right: 48px;
    top: 48px;
  }
}


.pent-2{
  .app__item-card-schema{
    width: 530px;
  }
}

.app__item-card-pent{
  .app__item-data-label{
    line-height: 140%;
  }
  .app__item-data-info:not(:last-child) {
    margin-bottom: 24px;
  }
  .app__item-data {
    margin-bottom: 0;
  }
  .app__item-card-schema{
    height: auto;
    padding: 0;
    img {
      width: 100%;
      max-height: 10000%;
    }
  }
}

.app__item-details-hint{
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #9D9D9C;
}

.app__item-card-dop-title{
  margin-bottom: 24px;
  font-size: 27px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-family: 'Garamond';
}


.app__item-card-dop-descr{
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      font-size: 18px;
      line-height: 100%;
      font-family: 'Garamond';
      letter-spacing: 0.25em;
      position: relative;
      &:not(:last-child){
        margin-bottom: 12px;
      }
    }
  }
}

.app__item-card-dop{
  position: absolute;
  right: 48px;
  top: 160px;
}

.pent-3{
  .app__item-card-type{
    position: relative;
    z-index: 3;
  }
  .app__item-card-schema {
    padding-left: 0;
    margin-left: 0;
    position: relative;
    padding-right: 48px;
    margin-top: 20px;
  }
  .app__card-box{
    &:not(:last-child){
      margin-bottom: 112px;
    }
  }

  .app__item-card-dop {
    bottom: 90px;
    left: -26px;
    right: auto;
    top: auto;
  }

  .pent__compas{
    z-index: 3;
  }

  .pent__schema-sm {
    bottom: 45px;
  }
}

.pent-5 {
  .app__card-box:not(:last-child) {
    margin-bottom: 36px;
  }

  .app__item-card-schema {
    margin-left: -44px;
    width: 641px !important;
    max-width: 100% !important;
    margin-top: 5px !important;
    margin-bottom: 100px;
  }

  .app__item-card-right-title {
    background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    font-family: 'Garamond';
    max-width: 501px;
    margin-bottom: -30px;
    position: relative;
    z-index: 4;
  }
}

.docs{
  background-image: -webkit-gradient(linear, left bottom, left top, from(#F4F2ED), to(#F4F2ED));
  background-image: -webkit-linear-gradient(bottom, #F4F2ED, #F4F2ED);
  background-image: linear-gradient(0deg, #F4F2ED, #F4F2ED);
  .container{
    padding: 110px 0 95px;
  }
}

.docs__item-icon{
  width: 72px;
  height: 72px;
  min-width: 72px;
  margin-right: 12px;
  svg{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.docs__item{
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  a{
    color: #fff !important;
    &:hover{
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
}

.footer__ct{
  margin-bottom: 60px;
}

.pent-2{
  .app__item-card-actions{
    position: absolute;
    bottom: -7px;
    right: 48px;
    white-space: nowrap;
    width: auto;
    .btn__small{
      min-width: 144px;
      &:not(:last-child){
        margin-right: 8px;
      }
    }
  }
}

.pent-3{

}


.form__after{
  margin-top: 24px;
  a{
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #A57C52 !important;
    border-bottom: 1px solid #A57C52;
    &:hover{
      border-bottom: 1px solid transparent;
    }
  }
}

.modal__label{
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
  a{
    cursor: pointer;
    margin-left: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #A57C52 !important;
    border-bottom: 1px solid #A57C52;
    &:hover{
      border-bottom: 1px solid transparent;
    }
  }
}

.lk__nav{
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 87px;
  z-index: 60;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    li{
      display: inline-block;
      vertical-align: top;
      font-size: 24px;
      line-height: 30px;
      font-family: 'Garamond';
      letter-spacing: 0.02em;
      padding-bottom: 12px;
      position: relative;
      &.active{
        &:after{
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
          position: absolute;
          height: 2px;
        }
        a{
          cursor: default;
        }
      }
      a{
        color: #101820 !important;
        &:hover{
          opacity: 0.75;
        }
      }
      &:not(:last-child){
        margin-right: 48px;
      }
    }
  }
}

.lk__box{
  .form__list{
    .row__custom{
      margin-right: -24px;
      margin-left: -24px;
      margin-bottom: -78px;
    }
    .col__custom{
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .form__item{
    margin-bottom: 78px;
  }

  .form__action{
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 78px;
  }

  .form__field{
    position: relative;
  }

  &--profile{
    padding-top: 48px;
  }
}

.deals__tb{
  .btn__custom-small{
    max-width: 168px;
    min-width: 0;
  }
  table{
    width: 100%;
    border-collapse: collapse;
  }
  thead{
    th{
      padding-top: 4px;
      padding-bottom: 16px;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #8C9091;
      &:not(:last-child){
        padding-right: 10px;
      }
    }
    tr{
      border-bottom: 1px solid #B4B4B4;
    }
  }
  td{
    &:not(:last-child){
      padding-right: 10px;
    }
    &:last-child{
      width: 20%;
    }
    padding-top: 24px;
    padding-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #101820;
  }
  tbody{
    tr{
      border-bottom: 1px solid #B4B4B4;
    }
  }
}

.btn__custom-small{
  height: 24px;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding: 0 20px;
  white-space: nowrap;
}

.lk__section{
  padding-bottom: 72px;
  &:not(:first-child){
    padding-top: 48px;
  }
}

.ct__item-col{
  width: 20%;
  min-width: 20%;
  .btn__custom-small{
    max-width: 168px;
    min-width: 0;
  }
}

.ct__item-label{
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #8C9091;
}

.ct__item-name{
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #101820;
}

.ct__item-value{
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #101820;
}

.ct__item{
  padding-top: 24px;
  padding-bottom: 24px;
  &:not(:last-child){
    margin-bottom: 12px;
  }
}

.deal__form-box{
  border: 1px solid #8B6E4B;
  padding: 72px 48px 96px;
  position: relative;
}

.dc__item-name{
  min-height: 72px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
  margin-bottom: 24px;
}

.dc__item-icon{
  width: 38px;
  height: 50px;
  background: url("../img/dc-icon.svg") no-repeat center;
  background-size: contain;
  margin-bottom: 24px;
}

.dc__item-date{
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #8C9091;
  margin-bottom: 24px;
}

.dc__item-bl{
  padding-right: 48px;
}

.icon__download{
  width: 14px;
  min-width: 14px;
  margin-right: 6px;
  height: 14px;
  position: relative;
  &:before, &:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &:before{
    background-image: url("../img/download-icon.svg");
  }
  &:after{
    opacity: 0;
    background-image: url("../img/download-icon-wh.svg");
  }
}

.btn__custom-small:hover{
  .icon__download{
    &:after{
      opacity: 1;
    }
    &:before{
      opacity: 0;
    }
  }
}

.dc__item{
  .btn__custom-small{
    font-size: 10px;
    min-width: 0;
    letter-spacing: 0.25em;
  }

  margin-bottom: 48px;
}

.dc__list{
  .row{
    margin-bottom: -48px;
  }
}

.deal__form-bl{
  width: 552px;
  margin: 0 auto;
  .checkbox__label{
    font-weight: normal;
    white-space: nowrap;
  }
  .checkbox__list{
    margin-left: -6px;
    margin-bottom: 36px;
  }
  .form__item{
    padding-bottom: 12px;
  }
}

.form__action-wrap{
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -36px;
  .btn__custom{
    background-color: #fff;
  }
}

.news__item-date{
  margin-bottom: 6px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #101820;
  opacity: 0.5;
}

.news__item-title{
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #101820;
  font-family: 'Garamond';
  a{
    color: #101820 !important;
    &:hover{
      color: #A57C52 !important;
    }
  }
}

.news__item-descr{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
  &:not(:last-child){
    margin-bottom: 24px;
  }
}

.news__item{
  padding-bottom: 48px;
  border-bottom: 1px solid #B4B4B4;
  &:not(:first-child){
    padding-top: 48px;
  }
}

.news__item-img{
  img{
    display: block;
    width: 100%;
  }
}

.form__select{
  position: relative;
}

.select__custom-input{
  display: block;
  height: 32px;
  border: none;
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
  border-bottom: 1px solid #B4B4B4;
  background: transparent;
  cursor: pointer;
  position: relative;
  &:after{
    content: '';
    right: 0;
    width: 14px;
    height: 8px;
    background: url("../img/select-icon.svg") no-repeat center;
    background-size: contain;
    margin-top: -4px;
    position: absolute;
  }
}

.select__custom{
  position: relative;
  &.opened{
    .select__custom-dropdown{
      opacity: 1;
      visibility: visible;
    }
    .select__custom-input:after{
      transform: rotateX(180deg);
    }
  }
}

.form__field{
  position: relative;
}

.select__custom-dropdown{
  position: absolute;
  top: 100%;
  left: -24px;
  right: -24px;
  padding: 24px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

.select__custom-dropdown-item{
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #101820;
  &:not(:last-child){
    margin-bottom: 24px;
  }
}

.has-error{
  .select__custom-input{
    border-color: #DD0B14;
  }
}

.news__item--cm {
  .news__item-bl {
    display: flex;
  }
}

.news__item-ct{
  width: 100%;
}

.news__item-img{
  width: 360px;
  min-width: 360px;
  margin-left: 24px;
  a{
    display: block;
    img{
      display: block;
      width: 100%;
    }
    &:hover{
      opacity: 0.75;
    }
  }
}

.news__list{
  .deal__form-box{
    margin-top: 90px;
    margin-bottom: 80px;
  }
}

.sc__news{
  .years__tabs-pane{
    &:not(.active){
      display: none !important;
    }
  }
  .years__month{
    &.active{
      opacity: 1;
    }
  }
  .years__tab{
    &:not(.active){
      opacity: 0.5;
    }
  }
}

.post__title{
  font-size: 34px;
  line-height: 40px;
  font-family: 'Garamond';
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

.seo__item{
   & > * {
     font: inherit !important;
     margin: 0;
   }
}

.post__header{
  margin-bottom: 72px;
}

.post__date{
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #8C9091;
}

.post__box-3{
  display: flex;
  align-items: flex-start;
  .post__box-right{
    width: 360px;
    min-width: 360px;
    margin-left: 36px;
  }
  .post__box-img{
    &:not(:last-child){
      margin-bottom: 48px;
    }
    img{
      margin: 0 !important;
    }
  }
}

.post__box-left{
  width: 100%;
}

.post__box-img{
  img{
    display: block;
    width: 100% !important;
    height: auto !important;
  }
}

.post__body{
  font-family: 'Lato' !important;
  font-weight: normal !important;
  & > *{
    &:first-child{
      margin-top: 0 !important;
    }
    &:last-child{
      margin-bottom: 0 !important;
    }
  }
  h3{
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: #0B2734;
    letter-spacing: 0.15em;
    font-family: 'Garamond';
    margin: 48px 0 24px 0;
    text-transform: uppercase;
    background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p{
    margin: 0 0 24px 0;
    letter-spacing: 0.05em;
    font-size: 16px;
    line-height: 24px;
  }
  img{
    display: block;
    max-width: 100%;
    margin: 48px auto 64px;
    height: auto !important;
  }
  h4{
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #0B2734;
    letter-spacing: 0.15em;
    font-family: 'Garamond';
    margin: 24px 0 24px 0;
    text-transform: uppercase;
    background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h1{
    font-weight: bold;
    font-size: 72px;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #0B2734;
    margin: 48px 0 32px 0;
  }
  h2{
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #0B2734;
    letter-spacing: 0.15em;
    font-family: 'Garamond';
    margin: 72px 0 48px 0;
    text-transform: uppercase;
    background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  blockquote{
    margin: 72px 0;
    padding: 24px;
    position: relative;
    text-align: center;
    font-family: 'Garamond';
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.03em;
    color: #1D1D1B;
    &:before{
      content: '';
      left: 50%;
      margin-left: -276px;
      top: 0;
      width: 552px;
      background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
      height: 1px;
      position: absolute;
    }
    &:after{
      content: '';
      left: 50%;
      margin-left: -276px;
      bottom: 0;
      width: 552px;
      background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
      height: 1px;
      position: absolute;
    }
  }

  ul{
    margin: 0 0 24px 0;
    list-style: none;
    padding: 0;
    li{
      position: relative;
      padding-left: 22px;
      font-size: 16px;
      line-height: 24px;
      &:before{
        content: '';
        background: #A57C52;
        position: absolute;
        left: 0;
        top: 9px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
      }
      &:not(:last-child){
        margin-bottom: 16px;
      }
    }
  }

  figcaption {
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    font-weight: normal;
    margin-top: -45px;
    margin-bottom: 40px;
  }

  p{
    a{
      color: #A57C52 !important;
      text-decoration: underline;
    }
  }
  ol{
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: list 0;

    li{
      position: relative;
      padding-left: 24px;
      font-size: 16px;
      line-height: 24px;
      color: #1D1D1B;
      min-height: 54px;
      &:not(:last-child){
        margin-bottom: 16px;
      }
      &:before{
        counter-increment: list;
        content: counter(list);
        position: absolute;
        top: 0;
        left: 0;
        color: #A57C52;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.box__3d-img img{
  opacity: 1 !important;
}

.app__item-card-schema-imgs{
  width: 100%;
  img{
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }
}

.app__item-card-schema-double{
  height: 100%;
}

.modal__app{
  .modal__box{
    width: 1270px;
    max-width: calc(100vw - 40px);
  }
}

.app__item-list{
  position: relative;
  cursor: pointer;
  &:hover{
    &:before{
      opacity: 1;
    }
  }
  &:before{
    left: -20px;
    right: -20px;
    top: 12px;
    bottom: 12px;
    background-color: #EDE5DC;
    position: absolute;
    content: '';
    opacity: 0;
  }
}

.toggle__subscribe-bl {
  cursor: pointer;
  span {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #101820;
    font-family: 'Garamond';
  }
}

.subscribe__box {
  margin-bottom: 64px;
}

.toggle__handle {
  background: rgba(#010202, 0.1);
  height: 14px;
  width: 32px;
  border-radius: 7px;
  position: relative;
  margin-left: 24px;
  &:before{
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(#010202, 0.1);
  }
  &.active {
    background-color: #A57C52;
    &:before{
      left: 20px;
    }
  }
}

.app__item-card-schema-item {
  img{
    margin-bottom: 0 !important;
  }
}

.app__item-slider-info {
  padding: 0 20px;
  margin-top: -10px;
}

.app__item-slider-info {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Garamond';
  letter-spacing: 0.02em;
}

.app__item-card--slider {
  .app__item-card-type{
    margin-bottom: 20px;
  }
  .app__item-data{
    margin-bottom: 8px;
  }
  .app__item-data {
    .app__item-data-info {
      &:not(:last-child){
        margin-right: 20px;
      }
    }
  }
  .app__item-card-building{
    margin-left: 36px;
  }
  .app__item-card-type{
    padding-left: 48px;
  }

  .app__item-card-schema-imgs {
    margin-bottom: 15px;
  }

  .app__item-card-schema{
    height: auto;
  }
}

.app__item-card-schema-item {
  .btn__small{
    span{
      text-transform: lowercase;
    }
  }
  &:first-child {
    .btn__small{
      span{
        margin-right: 18px;
      }
    }
  }
  &:last-child {
    .btn__small{
      span{
        margin-left: 18px;
      }
    }
  }
}

.app__item-card-schema-item-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Garamond';
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}

.map__loader{
  width: 64px;
  img{
    display: block;
    width: 100%;
  }
}

.app__item-card-pent {
  padding: 48px;
}

.pent__slide-col--1 {
  flex: 0 0 25%;
  max-width: 25%;
}

.pent__slide-col--2 {
  flex: 0 0 75%;
  max-width: 75%;
}

/*.pent__slide-col--3 {
  flex: 0 0 28.7%;
  max-width: 28.7%;
}*/

.pent__slide-col--4 {
  flex: 0 0 75.2%;
  max-width: 75.2%;
}

.app__item-card {
  position: relative;
}

.pent-1, .pent-4 {
  .app__card-box:not(:last-child) {
    margin-bottom: 25px;
  }

  .app__item-details {
    margin-bottom: 0;
  }

  .pent__slide-col--2 {
    .app__item-card-schema{
      margin-bottom: 48px;
    }
  }
  .app__item-card-type{
    margin-bottom: 0;
  }

  .app__item-card-right {
    position: absolute;
    left: 56px;
    height: auto;
    bottom: 83px;
    width: 260px;
  }

  .pent__schema-sm {
    margin-bottom: 24px;
    img {
      display: block;
      width: 100%;
    }
  }

  .app__item-card-dop {
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    margin-bottom: 400px;
  }

  .app__item-data-label--big {
    font-size: 20px;
  }

  .app__item-data-info {
    .app__item-data-value {
      font-size: 34px;
    }
  }

  .app__item-card-schema--3 {
    padding-top: 48px;
    img{
      max-width: 415px;
    }
  }

  .pent__slide--3 {
    .pent__slide-col--1 {
      margin-bottom: auto;
      padding-top: 92px;
    }
  }

  .pent__slider {
    .slick-current {
      z-index: 10;
    }
  }

  .btn__small {
    white-space: nowrap;
  }
}

.pent__slide-col--2 {
  .btn__small {
    &:not(:last-child){
      margin-right: 24px;
    }
  }
}

.pent__btn-list {
  .row {
    margin-right: -4px;
    margin-left: -4px;
  }
}

.pent__btn-col {
  padding-left: 4px;
  padding-right: 4px;
}

.btn__small--prev {
  span {
    margin-left: 21px;
  }
}

.btn__small--next {
  span {
    margin-right: 21px;
  }
}

.pent-3 {
  padding: 48px 0 !important;
  .app__item-card-right {
    position: absolute;
    bottom: 48px;
    right: 48px;
    width: 300px;
    height: auto;
    .btn__small {
      white-space: nowrap;
    }
  }
  .app__item-card-schema {
    margin-top: 64px;
  }
  .app__item-details {
    margin-bottom: 0;
  }
  .pent__schema-sm {
    margin-bottom: 24px;
    img{
      width: 100%;
    }
  }

  .app__item-data-label--big {
    font-size: 20px;
  }

  .app__item-data-info {
    .app__item-data-value {
      font-size: 34px;
    }
  }

  .app__item-card-schema {
    margin-left: -44px;
    width: auto;
  }
}

.pent__slide-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-family: 'Garamond';
  line-height: 30px;
  letter-spacing: 0.02em;
}

.pent-4 {
  .pent__slide-col--1{
    padding-top: 44px;
  }

  .pent__slider .slick-list {
    overflow: visible;
  }
  .app__card-box:not(:last-child) {
    margin-bottom: 44px;
  }
  .pent__slide-1{
    .pent__slide-col--4 {
      margin-top: -34px;
      padding-bottom: 24px;
    }
  }
}

.pent__schema-sm {
  max-width: 254px;
  margin-left: auto;
  margin-right: auto;
}

.video__frame {
  margin-top: 64px;
  margin-bottom: 64px;
  .video-js{
    margin-left: auto;
    margin-right: auto;
  }

  .tugrenev-skin {
    .vjs-poster{
      background-size: cover !important;
      &:after {
        content: '';
        position: absolute;
        background: url("../img/video-logo.svg") no-repeat center;
        background-size: contain;
        right: 35px;
        top: 35px;
        width: 102px;
        height: 102px;
      }
    }

    &:hover{
      .vjs-big-play-button{
        .vjs-icon-placeholder {
          opacity: 0.75;
        }
      }
    }

    .vjs-big-play-button {
      width: 148px;
      height: 148px;
      border-radius: 50%;
      margin-left: -74px;
      margin-top: -74px;
      background-color: #EFE7DB !important;
      position: absolute;
      left: 50%;
      top: 50%;
      border: none !important;
      .vjs-icon-placeholder {
        background: url("../img/video-play-btn.svg") no-repeat center;
        background-size: contain;
        left: 50%;
        top: 50%;
        width: 90px;
        height: 90px;
        margin-left: -45px;
        margin-top: -45px;
        position: absolute;
        &:before, &:after {
          display: none !important;
        }
      }
    }

    .vjs-control-bar {
      height: 50px;
      color: #BD9E7D !important;
      background-color: #EFE7DB;
    }

    .vjs-button > .vjs-icon-placeholder:before {
      font-size: 38px;
      line-height: 50px;
    }

    .vjs-load-progress {
      background: #BD9E7D !important;
    }

    .vjs-progress-holder {
      height: 8px;
    }

    .vjs-play-progress:before {
      font-size: 20px;
    }

    .vjs-time-control {
      font-size: 17px;
      line-height: 50px;
      font-family: 'Lato';
      text-transform: uppercase;
      font-weight: 700;
    }

    .vjs-volume-bar {
      margin: 20px 8px;
    }

    .vjs-playback-rate .vjs-playback-rate-value {
      font-size: 23px;
      line-height: 50px;
      font-family: 'Lato';
      text-transform: uppercase;
      font-weight: 700;
    }

    .vjs-progress-control .vjs-progress-holder {
      background: #fff;
    }

    .vjs-load-progress div {
      background: #cab197;
    }

    .vjs-play-progress {
      background-color: #BD9E7D;
    }

    .video-js .vjs-time-tooltip {
      font-family: 'Lato';
    }

    .vjs-slider-horizontal .vjs-volume-level {
      height: 8px;
    }

    .vjs-volume-bar.vjs-slider-horizontal {
      height: 8px;
    }

    .vjs-slider-horizontal .vjs-volume-level:before {
      font-size: 20px;
    }

    .vjs-menu-button-popup .vjs-menu {
      display: none !important;
    }

    .vjs-fullscreen-control.vjs-control.vjs-button,
    .vjs-play-control.vjs-control.vjs-button{
      width: 50px;
    }
  }
}

.promo__sl--50 {
  .promo__sl {
    width: 50%;
    height: 832px;
    margin-left: 50%;
  }
  .promo__sl-item-img {
    height: 832px;
    picture, img {
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1023px) {
  .promo__sl--50 {
    .promo__sl {
      height: 90vh;
    }
    .promo__sl-item-img {
      height: 90vh;
    }
  }

}

.news__item-source {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: rgba(#101820, 0.5);

  a {
    padding-left: 6px;
    background: linear-gradient(93.69deg, #946C4C 0%, #BD9E7D 24.74%, #8C675B 50.26%, #BD9E7D 76.82%, #946C4C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.news__list--mass-media {
  .news__item-title {
    margin-bottom: 15px;
  }

  .news__item-date{
    margin-bottom: 6px;
  }
}

.post__header {
  .news__item-source{
    margin-top: 6px;
  }
}

.post__after{
  margin-top: 70px;
}

.filter__form-btn {
  min-width: 115px;
  margin: 35px 0 -55px;
}

.app__item-card-pent {
  .app__item-card-type {
    margin-bottom: 25px;
  }
}

.pent-1 {
  .pent__slide-col--2 {
    @media screen and (min-width: 1023px) {
      .app__item-card-schema {
        width: 571px;
        margin: -90px auto 10px;
      }
    }
  }


  .pent__schema-sm{
    margin-bottom: 0;
    margin-top: 68px;
  }

  @media screen and (min-width: 1024px){

    .pent__slider .slick-list {
      overflow: visible;
    }


    .pent__btn-col--1,
    .pent__btn-col--2{
      position: absolute;
      bottom: 48px;
    }

    .pent__btn-col--2 {
      right: 123px;
    }

    .pent__btn-col--1{
      left: 355px;
    }
  }

  .pent__slide--2 {
    .pent__schema-sm{
      margin-bottom: -15px;
      margin-top: -15px;
    }

    .pent__slide-actions {
      margin-top: 21px;
    }
  }

  .pent__slide--3 {
    .pent__slide-col--1{
      padding-top: 35px;
    }
  }

  .app__item-card-dop {
    margin-bottom: 0;
    margin-top: 60px;
  }

  .app__item-card-schema--3 {
    img {
      max-width: 483px;
      margin-bottom: 26px;
    }
  }

  @media screen and (max-width: 1023px){
    .pent__schema-sm {
      max-width: 100%;
    }
  }
}

.pent-4 {
  .pent__slide-col--1 {
    padding-top: 0;
  }

  .pent__schema-sm {
    width: 200px;
    margin-left: 0;
    margin-top: -10px;
  }

  .pent__slide--1 {
    .app__item-card-schema {
      width: auto;
      margin-bottom: 288px;
    }
  }

  @media screen and (min-width: 1024px){
    .pent__btn-col--1,
    .pent__btn-col--2{
      position: absolute;
      bottom: 300px;
    }

    .pent__btn-col--2 {
      right: 123px;
    }

    .pent__btn-col--1{
      left: 355px;
    }
  }

  .pent__slide--2 {
    .app__item-card-schema {
      margin-top: 48px;
      margin-bottom: 272px;
    }
  }
}

.pent-3 {
  padding: 48px !important;

  .app__item-card-left{
    padding-left: 0;
  }

  .pent__schema-sm {
    width: 200px;
    margin-left: 0;
    margin-top: -15px;
  }

  .app__item-card-right-title {
    max-width: 100%;
  }

  .app__item-card-schema {
    margin-left: 0;
    width: auto !important;
    margin-right: -50px !important;
    max-width: 1000% !important;
  }

  .pent__btn-col {
    &:not(:last-child){
      margin-right: 100px;
    }
  }
}

.app__info-square {
  border: 1px dashed #000000;
  width: 29px;
  height: 27px;
  margin-right: 21px;
}

.pent-new--2 {
  .app__item-card-schema  {
    img {
      max-width: 825px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 42px;

      @media screen and (max-width: 1023px){
        margin-top: 0;
      }
    }
  }

  .pent__slide--1 {
    .app__item-card-schema {
      width: auto;
      margin-right: -50px;
      margin-top: -6px;
      margin-bottom: 15px;
    }
  }

  .pent__slide--2 {
    .app__item-card-schema {
      margin-top: -30px !important;
      margin-bottom: 44px !important;

      @media screen and (max-width: 1023px){
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }

    .app__item-card-schema img {
      max-width: 693px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 42px;

      @media screen and (max-width: 1023px){
        margin-top: 0;
      }
    }
  }

  .pent__btn-col--1, .pent__btn-col--2 {
    bottom: 157px;
  }
}

.finish__badge {
  position: absolute;
  width: 69px;
  height: 72px;
  background: url("../img/finish-badge.svg") no-repeat center;
  background-size: contain;
  left: 50%;
  z-index: 20;
  margin-left: -430px;
  padding-bottom: 26px;

  @media screen and (max-width: 1440px){
    left: 282px;
    margin-left: 0;
  }

  @media screen and (max-width: 1023px){
    width: 55px;
    height: 57px;
    padding-bottom: 18px;
    left: 55px;
  }

  @media screen and (max-width: 640px){
    left: 47px;
  }

  &-title {
    font-family: 'Garamond';
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    letter-spacing: 0.2em;

    @media screen and (max-width: 1023px){
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.15em;
    }
  }
}

.page-not-front {
  .finish__badge {
    @media screen and (max-width: 640px){
      left: 82px;
    }
  }
}

a, input, button, textarea, .nav__sl-arrow, .btn__custom:before,
.topics__item-bl:before, .info__close path, .partners__item-modal,
.scroll__up svg, .icon__custom:before, .icon__custom:after, .icon__callback,
.nav__close path, .icon__back path, .infra__item-bg-2, .infra__item-arrow,
.infra__item-img:before, .checkbox__decor:before, .checkbox__decor:after, .view__item-bl,
.btn__small-gr, .icon__loader, .pager__wrap span, .filter__form, .sort__toggle,
.sort__toggle path, .icon__arrow, .facade__res-item:before, .bank__item-logo,
.years__tab, .years__month, .infra__info-item-bl:after, .modal__box, .modal__custom,
.modal__close path, .infra__info-item, .infra__info-item-title, .inside__schema-data path,
.icon_pin, .out-area path, .map__pin, .active-pol, [data-facade] path, .icon__download:before, .icon__download:after,
.select__custom-dropdown, .select__custom-dropdown-item, .select__custom-input:after,
.app__item-list:before, .video__frame .tugrenev-skin .vjs-icon-placeholder{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.box__3d{
  img{
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    -ms-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
  }
}

[data-facade] path, [data-facade] polygon, [data-facade] line{
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.header{
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.nav__box{
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.app__item-data-info-lbl {
  font-size: 20px;

}

.text-border {
  border-bottom: 1px solid rgba(#BD9E7D, 0.6);
}

.app__item-data-info--small {
  font-size: 18px;
}

.app__item-pent-data {
  .app__item-data-info:not(:last-child) {
    margin-bottom: 0;
  }

  .app__item-data-info--top {
    margin-top: 16px;
  }
}

.modal__img-area {
  background: #D9D9D9;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.modal__video--img {
  .modal__close {
    path {
      stroke: #000;
    }
  }
}

.card__media-sl-row {
  margin-left: -7px;
  margin-right: -7px;

  @media screen and (max-width: 1023px){
    margin-left: -17px;
    margin-right: -17px;
  }
}

.card__media-sl {
  padding-right: 110px;
  padding-left: 50px;
  margin-top: 40px;
  position: relative;

  @media screen and (max-width: 1023px){
    padding-right: 30px;
    padding-left: 30px;
  }

  @media screen and (max-width: 767px){
    padding-right: 0;
    padding-left: 0;
    margin-left: -5.53vw;
    margin-right: -5.53vw;

    .slick-track {
      margin-left: auto;
      margin-right: auto;
    }

    .slick-list {
      overflow: visible;
    }
  }
}

.card__media-it {
  padding-right: 7px;
  padding-left: 7px;

  &-img {
    height: 120px;
    position: relative;
    background: #D9D9D9;
    cursor: pointer;

    @media screen and (max-width: 767px){
      height: 40vw;
    }

    &:hover {
      .card__media-play {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.card__media-play {
  width: 38px;
  height: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -19px;
  margin-top: -19px;
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  @media screen and (max-width: 767px){
    opacity: 1;
    visibility: visible;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.modal__video {
  background: rgba(255, 255, 255, 0.75);
  .modal__box {
    width: 1024px;
    padding: 0;

    @media screen and (max-width: 1199px){
      width: 900px;
    }

    @media screen and (max-width: 767px){
      width: 100vw;
      min-height: 58vw;
    }
  }

  .modal__close {
    z-index: 10;

    right: 0;
    top: 0;
  }
}

.modal__video-area {
  font-size: 0;
  height: 580px;
  line-height: 1;

  @media screen and (max-width: 1199px){
    height: 480px;
  }

  @media screen and (max-width: 767px){
    height: 58vw;
  }

  & > div {
    width: 100% !important;
    height: 100% !important;

    video {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
}

.card__media-sl {
  .slick-arrow {
    position: absolute;
    right: 100%;
    width: 25px;
    height: 6px;
    cursor: pointer;
    top: 50%;
    border: none;
    background-color: transparent;
    font-size: 0;

    &.slick-prev {
      margin-right: 6px;
      right: 100%;
      background: url("../img/sl-prev-arr.svg") no-repeat center;
      background-size: contain;

      @media screen and (max-width: 1023px){
        background: url("../img/sl-prev-arr-sm.svg") no-repeat center;
        background-size: contain;
        width: 3vw;
        height: 4.8vw;
        left: 50%;
        margin-left: -28vw;
        right: auto;
        margin-right: 0;
        z-index: 5;
        margin-top: -2.4vw;
        border: none;

        &:before {
          left: -2.14vw;
          right: -2.14vw;
          bottom: -2.14vw;
          top: -2.14vw;
          position: absolute;
          content: '';
        }
      }
    }

    &.slick-next {
      margin-left: 6px;
      left: 100%;
      background: url("../img/sl-next-arr.svg") no-repeat center;
      background-size: contain;

      @media screen and (max-width: 1023px){
        background: url("../img/sl-next-arr-sm.svg") no-repeat center;
        background-size: contain;
        width: 3vw;
        height: 4.8vw;
        right: 50%;
        margin-right: -28vw;
        left: auto;
        margin-left: 0;
        z-index: 5;
        margin-top: -2.4vw;
        border: none;

        &:before {
          left: -2.14vw;
          right: -2.14vw;
          bottom: -2.14vw;
          top: -2.14vw;
          position: absolute;
          content: '';
        }
      }
    }

    &.slick-disabled {
      cursor: default;
      opacity: 0.4 !important;
    }
  }
}

@media screen and (min-width: 1024px){
  .pent-4:not(.pent-new--1):not(.pent-new--2) {
    .pent__media-box {
      position: absolute;
      top: 900px;
      right: 25px;
      left: 25px;
    }
  }

  .pent-4.pent-new--2 {
    .pent__media-box {
      position: absolute;
      bottom: 60px;
      right: 25px;
      left: 25px;
    }
  }

  .pent-4.pent-new--1 {
    .pent__slide--1 {
      .app__item-card-schema {
        margin-bottom: 50px;
      }
    }

    .pent__slide--2 {
      .app__item-card-schema {
        margin-bottom: 109px !important;
      }
    }

    .pent__btn-col--1, .pent__btn-col--2 {
      bottom: auto;
      top: 834px;
    }


    .pent__media-box {
      position: absolute;
      bottom: 60px;
      right: 25px;
      left: 25px;
    }
  }

  .pent-3 {
    .pent__media-box {
      position: absolute;
      top: 860px;
      right: 25px;
      left: 25px;
    }
  }

  .pent-1 {
    .card__media-sl {
      padding-right: 80px;
      margin-top: -60px !important;
      padding-left: 67px;
    }
  }

  .pent__list {
    .card__media-sl {
      padding-right: 80px;
      margin-top: 0;
      padding-left: 67px;
    }

    .pent-4:not(.pent-new--1):not(.pent-new--2) .pent__btn-col--1, .pent-4:not(.pent-new--1):not(.pent-new--2) .pent__btn-col--2 {
      bottom: auto;
      top: 720px;
    }

    .pent-4:not(.pent-new--1):not(.pent-new--2) .pent__slide--1 .app__item-card-schema {
      width: auto;
      margin-bottom: 67px;
    }

    .pent-4:not(.pent-new--1):not(.pent-new--2) .pent__slide--2 .app__item-card-schema {
      margin-top: 48px;
      margin-bottom: 48px;
    }

    .pent-new--2 .pent__btn-col--1, .pent-new--2 .pent__btn-col--2 {
      bottom: auto;
      top: 800px;
    }

    .pent-new--2 {
      .pent__slide--1{
        .app__item-card-schema {
          margin-bottom: 57px;
        }
      }

      .pent__slide--2{
        .app__item-card-schema {
          margin-bottom: 85px !important;
        }
      }
    }

    .pent-1 .pent__btn-col--1, .pent-1 .pent__btn-col--2 {
      bottom: auto;
      top: 786px;
    }

    .pent-1 .pent__btn-col--2 {
      right: 80px;
    }
  }
}

.modal__img-area {
  height: 580px;

  @media screen and (max-width: 1023px) {
    height: auto;
  }
}

.modal__box {
  .slick-arrow{
    width: 28px;
    height: 14px;
    position: absolute;
    border-color: transparent;
    top: 50%;
    margin-top: -7px;
    z-index: 10;
    cursor: pointer;

    &.slick-disabled {
      cursor: default;
      opacity: 0.4 !important;
    }

    &.slick-prev {
      background: url("../img/sl-prev-arr-md.svg") no-repeat center;
      background-size: contain;
      right: 100%;
      margin-right: 10px;

      @media screen and (max-width: 1023px) {
        width: 3.2vw;
        height: 6.14vw;
        margin-top: -3.07vw;
        right: auto;
        margin-right: 0;
        left: 6vw;
        background: url("../img/sl-prev-arr-md-sm.svg") no-repeat center;
        background-size: contain;
        border: none !important;
      }
    }

    &.slick-next {
      background: url("../img/sl-next-arr-md.svg") no-repeat center;
      background-size: contain;
      left: 100%;
      margin-left: 10px;

      @media screen and (max-width: 1023px) {
        width: 3.2vw;
        height: 6.14vw;
        margin-top: -3.07vw;
        left: auto;
        margin-left: 0;
        right: 6vw;
        background: url("../img/sl-next-arr-md-sm.svg") no-repeat center;
        background-size: contain;
        border: none !important;
      }
    }
  }
}

.card__sl-nav {
  position: absolute;
  right: 100%;
  width: 25px;
  height: 6px;
  cursor: pointer;
  top: 50%;

  &--prev {
    margin-right: -38px;
    right: 100%;
  }

  &--next {
    margin-left: -98px;
    left: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    bottom: -5px;
    top: -5px;
    right: -5px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.card__media-nav {
  width: 26px;
  height: 7px;
  position: absolute;
  top: 50%;
  margin-top: -3.5px;
  z-index: 4;
  cursor: pointer;

  &--prev {
    left: 10px;

    @media screen and (max-width: 1023px){
      left: -5px;
    }
  }

  &--next {
    right: 70px;

    @media screen and (max-width: 1023px){
      right: -5px;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1441px){
  .dots {
    left: auto;
    margin-left: 0;
    right: 72px !important;
  }

  .sc__promo {
    height: 830px;
  }
}

@media screen and (max-width: 1439px){
  .advant__slider-box .nav__sl-arrow.nav__sl-arrow-left {
    margin-left: calc(-1 * 50vw + 8px);
  }

  .advant__slider-box .nav__sl-arrow.nav__sl-arrow-right {
    margin-right: calc(-1 * 50vw + 8px);
  }

  .inside__nav {
    margin-left: 0;
    left: auto;
    right: 96px;
  }

  .inside__nav ul li {
    font-size: 12px;
  }

  .sc__about-history .scroll__up , .sc__about-arch .scroll__up{
    margin-left: 0;
    left: auto;
    right: 12px;
  }

  .back__wrap {
    left: 238px;
    margin-left: 0;
  }

  .progress__sl-box{
    .nav__sl-arrow-left{
      margin-right: calc(100vw / 2 - 78px);
    }

    .nav__sl-arrow-right{
      margin-left: calc(100vw / 2 - 78px);
    }
  }
}

@media screen and (max-width: 1359px){
  .sc__about-history .scroll__up, .sc__about-arch .scroll__up{
    display: none !important;
  }

  .pent-2{
    .app__item-card-schema {
      width: 436px;
    }
  }

  .app__item-card-dop-title {
    font-size: 24px;
  }

  .app__item-card-dop-descr ul li {
    font-size: 16px;
  }

  .app__item-card-pent{
    .app__item-data-label {
      font-size: 13px;
    }
    .app__item-card-type{
      white-space: nowrap;
      position: relative;
      z-index: 4;
    }
  }

  .pent-3{
    .app__item-card-schema {
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 1351px){
  .filter-sep{
    display: none !important;
  }
}

@media screen and (max-width: 1320px) and (min-width: 1199px) {
  .lk__nav {
    padding-left: 140px;
  }
}

@media screen and (max-width: 1350px) and (min-width: 1024px){
  .app__list-table {
    margin-left: -30px;
    margin-right: -30px;
  }

  .app__item-list-data {
    font-size: 21px;
  }

  .sorting__item {
    font-size: 13px;
    line-height: 20px;
  }

  .container{
    padding-left: 75px;
    padding-right: 75px;
  }

  .comfort__item-ct {
    padding: 24px;
  }

  .comfort__item-icon {
    width: 64px;
    min-width: 64px;
    height: 64px;
  }

  .footer .callback__toggle {
    top: -48px;
    left: 32px;
    margin-left: 0;
  }

  .sc__filter-params{
    .filter__nav-box {
      left: 30px;
    }
  }

  .infra__item:nth-child(1) .infra__item-bl:hover .infra__item-arrow {
    right: 146px;
  }

  .infra__item:nth-child(2) .infra__item-bl:hover .infra__item-arrow {
    left: 146px;
  }

  .back__wrap {
    top: 97px;
    left: 230px;
    margin-left: 0;
  }

  .filter__facade-left {
    left: 30px;
  }

  .checkbox__list{
    flex-wrap: wrap;
  }

  .filter-sep{
    margin-bottom: 40px;
    width: 100%;
  }

  .checkbox__vip-box {
    left: 0;
    bottom: -19px;
  }

  .view__item-bl{
    min-width: 72px;
  }

  .pent-1 {
    .pent__slide-col--2 {
      .app__item-card-schema {
        width: 529px;
      }
    }

    .pent__btn-col--1 {
      left: 285px;
    }

    .pent__btn-col--2 {
      right: 86px;
    }

    .pent__schema-sm {
      max-width: 197px;
      margin-left: 0;
      margin-right: auto;
      margin-top: -5px;
    }

    .pent__slide--3 {
      .pent__slide-col--2 {
        .app__item-card-schema {
          width: 444px;
        }
      }
    }
  }

  .pent-4 {
    .pent__btn-col--1,
    .pent__btn-col--2 {
      position: absolute;
      bottom: 265px;
    }

    .pent__slide--2 {
      .app__item-card-schema {
        margin-bottom: 58px;
      }
    }
  }

  .app__item-card-pent .app__item-data-label {
    font-size: 17px;
  }
}

@media screen and (max-width: 1370px) and (min-width: 1024px){
  .nav__data-box-value {
    font-size: 20px;
    line-height: 26px;
  }

  .nav__logo {
    width: 130px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 1024px) and (max-height: 820px){
  .nav__logo {
    width: 130px;
    margin-top: 35px;
  }
}

@media screen and (min-width: 1024px) and (max-height: 700px){
  .nav__logo {
    width: 110px;
    margin-top: 35px;
    margin-bottom: 28px;
  }

  .nav__menu ul li:not(:last-child) {
    margin-bottom: 32px;
  }

  .nav__data-box:not(:last-child) {
    margin-bottom: 26px;
  }

  .nav__menu ul li {
    font-size: 26px;
    line-height: 26px;
  }
}

@media screen and (max-width: 1299px){
  .inside__nav ul li {
    font-size: 11px;
  }
}

@media screen and (max-width: 1238px){
  .inside__nav{
    display: none !important;
  }
}

@media screen and (max-width: 1199px){
  .topics__item-title {
    font-size: 17px;
  }

  .sc__content-border:before{
    display: none !important;
  }

  .topics__item-descr{
    br{
      display: none;
    }
  }

  .advant__slider-box .nav__sl-arrow{
    display: none !important;
  }

  .page-front{
    .respect__decor {
      width: 900px;
      margin-top: -57px;
      margin-left: -450px;
    }
  }

  .infra__item-bl{
    max-width: 456px;
  }

  .infra__item:last-child{
    margin-top: 48px;
    .infra__item-bl{
      margin-right: 0;
      margin-left: auto;
    }
  }

  .facade__sl-box{
    padding-left: 96px;
  }

  .sc__infra-inside{
    .container {
      padding-top: 100px;
    }
  }

  .brad__nav {
    top: 162px;
  }

  .outside__schema-img {
    margin-left: -35px;
    margin-right: -35px;
  }

  .pent-2{
    .app__item-card-schema {
      width: 377px;
    }

    .pent__schema-sm {
      right: 48px;
      bottom: -10px;
    }
  }

  .deals__tb td{
    font-size: 12px;
  }

}

@media screen and (max-width: 1199px) and (min-width: 768px){
  .lk__nav ul li {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding-bottom: 10px;
  }

  .lk__nav{
    padding-left: 128px;
  }

  .lk__nav ul li:not(:last-child) {
    margin-right: 24px;
  }
}

@media screen and (min-width: 1024px){
  .hidden-sm-up{
    display: none !important;
  }

  .desk-nav-hidden {
    display: none !important;
  }

  .app__item-card-schema{
    padding-left: 25px;
    padding-right: 25px;
  }

  .app__item[data-id="6230990"] {
    .app__item-card-type{
      margin-right: -24px;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1270px) {
  .card__media-sl {
    margin-left: -7rem;
    margin-right: -1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
  .lk__nav {
    padding-left: 48px;
    position: relative;
    margin-top: -76px;
    top: 0;
    margin-bottom: 48px;
  }

  .deals__tb .btn__custom-small, .ct__item-col .btn__custom-small{
    letter-spacing: 0.05em;
  }

  .lk__section {
    padding-bottom: 48px;
  }

  .deals__tb td{
    font-size: 11px;
  }

  .deals__tb thead th{
    font-size: 8px;
  }

  .form__action-wrap .btn__custom {
    min-width: 300px;
  }
}

@media screen and (max-width: 1023px){
  .hidden-sm-down{
    display: none !important;
  }
  .promo__sl-item{
    height: 100vh;
    position: relative;
    &:after{
      content: '';
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      height: 144px;
      left: 0;
      width: 100%;
      z-index: 2;
    }
  }

  .promo__sl-item-img{
    height: 100%;
    picture{
      width: 100%;
      height: 100%;
    }
    img{
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .sc__promo{
    .nav__sl-arrow{
      &:not(:last-child){
        margin-right: 10px;
      }
    }

    .nav__sl-box {
      bottom: 36px;
    }
  }

  .container{
    padding-right: 37.5px;
    padding-left: 37.5px;
  }

  .slogan__box {
    width: 260px;
    height: 48px;
    top: 293px;
    left: 0;
  }

  .scroll__down{
    display: none !important;
  }

  .logo__box {
    position: absolute;
    left: 50%;
    margin-left: -48px;
    top: 36px;
  }

  .hd__item-bars{
    position: absolute;
    right: 0;
    top: 20px;
  }

  .promo__sl-info-box {
    bottom: 123px;
    margin-bottom: 0;
  }

  .app__item-list:before {
    top: 6px;
    bottom: 6px;
  }

  .promo__sl-info:before{
    display: none !important;
  }

  .promo__sl-info {
    font-size: 12px;
    letter-spacing: 0.05em;
    padding: 0 11px;
    height: auto;
  }

  .page-front .header{
    background: none !important;
  }

  .sc__promo .slick-dots {
    right: 13px;
  }

  .social {
    z-index: 20;
    bottom: 33px;
    right: auto;
    width: 16px;
    left: 17px;
  }

  .sc__common{
    .container {
      padding-top: 54px;
      padding-bottom: 48px;
    }
  }

  .sc__title {
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.1em;
  }

  .sc__subtitle {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.02em;
  }

  .text {
    line-height: 140%;
  }

  .sc__box:not(:last-child) {
    margin-bottom: 48px;
  }

  .sc__info{
    .sc__header {
      margin-bottom: 48px;
    }
  }

  .info__item{
    border-right: none !important;
    &:not(:last-child){
      margin-bottom: 36px;

      border-bottom: 1px solid #BDBDBD;
      .info__item-bl{
        padding-bottom: 36px;
      }
    }
  }

  .sc__info .actions__wrap {
    padding-top: 0;
  }

  .btn__custom {
    padding: 0 10px;
    min-width: 100%;
    letter-spacing: 0.3em;
  }

  .topics__item-descr br {
    display: block;
  }

  .topics__item-bl {
    padding-top: 170.77778%;
  }

  .topics__item-title {
    font-size: 24px;
  }

  .topics__list-row{
    position: relative;
    .nav__sl-box {
      bottom: 36px;
    }
    .nav__sl-arrow{
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }

  .sc__advantages{
    .sc__header {
      margin-bottom: 24px;
    }
  }

  .advant__slider-box{
    .nav__sl-arrow{
      top: 35vw;
      margin-top: -55px;
      opacity: 1 !important;
    }
    .nav__sl-arrow.nav__sl-arrow-left{
      left: -37.5px;
      margin-left: 0;
      display: flex !important;
      z-index: 10;
    }
    .nav__sl-arrow.nav__sl-arrow-right{
      right: -37.5px;
      margin-right: 0;
      display: flex !important;
      z-index: 10;
    }
  }

  .respect__decor {
    width: 1128px;
    margin-top: -72px;
    margin-left: -564px;
  }

  .slick-dots {
    margin: 40px 0 0;
  }

  .sc__partners{
    .sc__subtitle {
      font-size: 34px;
      line-height: 42px;
    }
  }

  .sc__partners .sc__header {
    margin-bottom: 48px;
  }

  .partners__list {
    padding-bottom: 48px;
  }

  .partners__item {
    width: 100%;
    min-width: 100%;
    &:not(:last-child){
      margin-bottom: 48px;
    }
  }

  .partners__item-modal {
    position: fixed;
    width: 100vw;
    left: 0;
    margin-left: 0;
    top: 0;
    background: #fff;
    z-index: 200;
    min-height: 100vh;
    cursor: default;
    padding: 0;
    overflow-y: auto;
    box-shadow: none !important;
  }

  .partners__item-modal-box{
    padding: 48px;
  }

  .info__close {
    width: 24px;
    height: 24px;
    top: 36px;
    right: 48px;
  }

  .sc__contacts{
    .container {
      padding-top: 48px;
    }
  }

  .map{
    height: 420px;
    margin-left: -37.5px;
    margin-right: -37.5px;
  }

  .footer__phone {
    margin-bottom: 36px;
    text-align: center;
  }

  .footer{
    .container {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  .footer__center{
    margin-top: 36px;
    margin-bottom: 36px;
  }

  .footer__ct  > .row > .col__custom:last-child{
    .footer__nav{
      li{
        text-align: center;
      }
    }
  }

  .content__box-indent {
    padding-top: 36px;
  }

  .footer__info {
    margin-top: 16px;
  }

  .callback__toggle {
    width: 60px;
    height: 60px;
    position: fixed;
    left: auto;
    right: 0;
    bottom: 24px;
    z-index: 200;
    margin-left: 0;
    cursor: pointer;
  }

  .icon__callback {
    width: 16px;
    height: 16px;
  }

  .scroll__up {
    width: 72px;
    height: 136px;
    left: auto;
    margin-left: 0;
    bottom: -44px;
    z-index: 50;
    right: 0;
  }

  .dots {
    right: 17px;
  }

  .container{
    padding-left: 65px;
    padding-right: 65px;
  }

  .page-about .sc__screen:not(:first-child).sc__about-history .container {
    padding-top: 44px;
  }

  .sc__about-history{
    .sc__subtitle{
      font-size: 32px;
      line-height: 120%;
    }
    .sc__header{
      margin-bottom: 60px;
    }
    .content__img{
      margin-right: -37.5px;
      margin-left: -37.5px;
    }
  }

  .dots {
    margin: 0;
    position: fixed;
    width: 48px;
    display: flex;
    justify-content: center;
    right: 0 !important;
    left: auto;
    top: 160px;
    z-index: 20;
    background: #fff;
    padding: 36px 0;
  }

  .content__img-right:after{
    display: none !important;
  }

  .content__img-right {
    padding-left: 0;
    margin-top: 0;
    padding-top: 48px;
    padding-right: 0;
    background: transparent;
    position: relative;
    .content__img{
      margin-top: 48px;
    }
  }

  .quote__item {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .content__img-right .quote__item {
    margin-top: 48px;
  }

  .page-about{
    .sc__about-history{
      .content__box-indent {
        padding-top: 0;
      }
    }
  }

  .chess__list{
    .chess__item .row {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }

    .chess__item{
      &:not(:last-child){
        margin-bottom: 48px;
      }
    }
  }

  .chess__item-ct-box {
    padding: 0 0 48px 0;
  }

  .chess__item-img-bl{
    margin-left: -37.5px;
    margin-right: -37.5px;
  }

  .sc__about-info{
    .info__list{
      padding-top: 48px;
    }
  }

  .comfort__item-bl {
    padding-top: 80% !important;
  }

  .page-not-front .section:last-child.sc__about-comfort .container {
    padding-bottom: 48px;
  }

  .sc__about-arch, .sc__about-info, .sc__about-area, .sc__about-finish, .sc__about-lobby, .sc__about-eng,
  .sc__about-services, .sc__about-comfort, .sc__apartments, .sc__panorama, .sc__filter-facade, .sc__3d, .sc__buy{
    .sc__subtitle{
      font-size: 33px;
      letter-spacing: 0.16em;
    }
  }

  .sc__pent-plans{
    .sc__subtitle{
      font-size: 30px;
      line-height: 42px;
      letter-spacing: 0.2em;
    }
  }

  .sc__filter-facade, .sc__3d, .sc__buy, .sc__brokers{
    .sc__subtitle{
      line-height: 120%;
    }
  }

  .app__item-card-pent .app__item-data-info:not(:last-child) {
    margin-bottom: 24px;
  }

  .filter__facade-left {
    position: relative;
    width: 100%;
    left: 0;
    margin-bottom: 48px;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    .filter__nav-box {
      width: 96px;
      min-width: 96px;
      margin-right: 12px;
      margin-bottom: 0;
    }

    .filter__compas {
      width: 72px;
      margin-top: 0;
    }
  }

  .facade__nav-box{
    margin-right: -28px;
    margin-left: -28px;
  }

  .nav__text{
    font-size: 11px;
    line-height: 13px;
  }

  .schema__1 .per__text-2 {
    transform: rotate(
                    -25deg
    );
    bottom: 1px;
    right: 55px;
  }

  .schema__1 .per__text-1 {
    transform: rotate(
                    16deg
    );
    bottom: 4px;
    left: 26px;
  }

  .facade__item-schema {
    height: auto;
  }

  .schema__1 .facade__item-schema {
    margin-left: -17px;
    margin-right: -10px;
  }

  .facade__sl-box {
    padding-left: 0;
    padding-right: 0;
    margin-left: -20px;
    margin-right: -20px;
  }

  .filter__facade-left:before {
    display: none !important;
  }


  .sc__infra{
    .sc__subtitle{
      font-size: 23px;
      line-height: 120%;

      letter-spacing: 0.2em;
    }
  }

  .sc__about-comfort{
    .text{
      text-align: left;
      br{
        display: none;
      }
    }
  }

  .sc__about-safety{
    .sc__subtitle{
      font-size: 24px;
      letter-spacing: 0.2em;
    }
  }

  .sc__about-eng{
    .text{
      text-align: left;
    }
  }

  .sc__about-lobby{
    .text{
      text-align: left !important;
    }
  }

  .chess__item-title {
    margin-bottom: 48px;
    text-align: center;
    font-size: 20px;
  }

  .sc__about-arch{
    .sc__subtitle{
      letter-spacing: 0.08em;
    }
  }

  .sc__about-finish{
    .text{
      text-align: left !important;
      br{
        display: none;
      }
    }
    .btn__custom{
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.11em;
    }

    .btn__list .btn__custom {
      min-width: 100%;
    }
  }

  .page-about .content__box-indent {
    padding-top: 0;
  }

  .content__sl-box{
    margin-left: -37.5px;
    margin-right: -37.5px;
    .content__sl-item{
      height: 640px;
      position: relative;
      overflow: hidden;
    }
    .content__sl-item-img{
      height: 100%;
      left: 50%;
      top: 0;
      position: absolute;

      transform: translateX(-50%);
      img{
        display: block;
        height: 100%;
        width: auto;
      }
    }
  }

  .content__sl-nav-box {
    padding: 24px 48px 24px 8px;
    .nav__sl-arrow{
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }

  .content__box-descr {
    padding: 30px;
    margin-top: 30px;
  }

  .page-about .sc__screen:not(:first-child) .container {
    padding-top: 96px;
  }

  .sc__screen + .sc__dark {
    margin-top: 72px;
    margin-bottom: 0 !important;
  }

  #services .container, #engineering .container, #safety .container {
    padding-bottom: 48px;
  }

  .services__item-bl {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  #services .container{
    padding-bottom: 24px;
  }

  #safety .container {
    padding-bottom: 24px;
  }

  .sc__infra{
    .container{
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  .infra__item-bl {
    height: 231px;
    padding: 24px;
  }

  .infra__item-img {
    width: 206px;
    right: -20px;
    top: -20px;
  }

  .infra__item:nth-child(2) .infra__item-img {
    right: auto;
    top: auto;
    bottom: -20px;
    left: -20px;
  }

  .infra__item-img:after {
    top: 20px;
    right: 20px;
  }

  .infra__item-title {
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.2em;
    span {
      font-size: 24px;
      line-height: 110%;
    }
  }

  .infra__item:nth-child(2) .infra__item-img:after {
    bottom: 20px;
    left: 20px;
  }

  .infra__item-arrow{
    width: 142px;
    height: 7px;
  }

  .infra__item{
    &:nth-child(1){
      .infra__item-arrow {
        top: 42px !important;
        right: 42px !important;
      }
    }
  }

  .infra__item{
    &:nth-child(2){
      .infra__item-arrow {
        bottom: 42px !important;
        left: 42px !important;
      }
    }
  }

  body:not(.page-about):not(.page-front){
    .slogan__box{
      display: none !important;
    }

    .header .container {
      padding: 10px 15px;
      max-width: 100%;
      align-items: flex-start !important;
    }

    .logo__box {
      width: 54px;
    }

    .hd__item:after{
      display: none !important;
    }

    .header__right{
      padding-top: 3px;
    }

    .logo__box {
      position: relative;
      left: 0;
      margin-left: 0;
      top: 0;
    }

    .hd__item {
      padding: 0;
    }

    .hd__item.hidden-sm-down{
      display: block !important;
    }

    .hd__item-bars {
      position: relative;
      right: -10px;
      top: 0;
    }

    .hd__item-bars a{
      overflow: hidden;
    }

    .hd__item--indent {
      margin-right: 3rem;
    }

    .hd__item--lang {
      top: 13px;
    }
  }

  .back__wrap {
    position: absolute;
    top: 120px;
    left: 48px;
    margin-left: 0;
    z-index: 30;
  }

  .page-apartments{
    .container {
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  .btn__list{
    flex-wrap: wrap;
  }

  .btn__list .btn__custom {
    padding: 0 20px;
    min-width: 100%;
  }

  .btn__list .btn__custom:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
  }

  .text ul{
    margin-bottom: 0 !important;
  }

  .text ul.list-col-2 li, .text ul.list-col-3 li {
    width: calc(100% - 24px);
    min-width: calc(100% - 24px);
    margin-left: 12px;
    margin-right: 12px;
    &:last-child{
      margin-bottom: 0 !important;
    }
  }

  .page-apartments{
    .scroll__up{
      bottom: 0;
    }
  }

  .box__3d {
    margin-left: -48px;
    margin-right: -48px;
    height: 660px;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .sc__filter-facade, .sc__filter-start{
    .container{
      padding-top: 110px !important;
    }
  }

  .breadcrumbs li:not(:last-child) {
    margin-right: 48px;
  }

  .breadcrumbs li {
    font-size: 11px;
  }

  .breadcrumbs {
    top: 193px;
    margin-left: -150px;
    width: 300px;
  }

  .schema__3 .facade__item-schema {
    margin-left: -23px;
    margin-right: -20px;
  }

  .facade__numb-header{
    margin-top: 48px;
  }

  .schema__2 .facade__item-schema {
    margin-left: -6px;
    margin-right: -14px;
    margin-top: 0;
  }

  .schema__2 .per__text-1 {
    right: 8px;
    transform: rotate(
                    -14deg
    );
    bottom: 6px;
  }

  .schema__5 .per__text-2 {
    left: 71px;
    transform: rotate(
                    20deg
    );
    bottom: -9px;
  }

  .schema__4 .facade__item-schema {
    margin-left: -16px;
    margin-right: -10px;
  }

  .facade__res-col-2 {
    width: 90px;
    min-width: 90px;
  }

  .facade__res-col-4 {
    font-size: 14px;
  }

  .page-not-front .section:last-child .container {
    padding-bottom: 64px;
  }

  .facade__res-item:nth-child(1) .facade__res-col-1 {
    font-size: 29px;
  }

  .facade__res-col-1 {
    width: 110px;
    min-width: 110px;
    font-size: 21px;
  }

  .schema__5 .facade__item-schema {
    margin-left: -27px;
    margin-right: -19px;
  }

  .breadcrumbs li:not(:last-child):after {
    margin-left: 11px;
  }

  .facade__item-schema {
    height: 64vw;
  }

  .schema__6 .facade__item-schema {
    margin-left: -23px;
    margin-right: -21px;
  }

  .sc__filter-start{
    .text{
      text-align: left;
    }
  }

  .sc__filter-start .container {
    padding-bottom: 48px;
  }

  .breadcrumbs{
    white-space: nowrap;
  }

  .sc__filter .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .sc__filter-params{
    .filter__nav-box {
      position: relative;
      width: 96px;
      top: 0;
      margin-top: 0;
      display: block;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      &:before{
        content: '';
        height: 1px;
        background: #A57C52;
        position: absolute;
        top: 50%;
        right: 100%;
        margin-right: 12px;
        width: 800px;
      }
      &:after{
        content: '';
        height: 1px;
        background: #A57C52;
        position: absolute;
        top: 50%;
        left: 100%;
        margin-left: 12px;
        width: 800px;
      }
    }
  }

  .filter-floors-sep{
    width: 100%;
  }

  .filter__col {
    margin-bottom: 24px;
    &:nth-child(1){
      order: -1;
    }
    &:nth-child(2){
      order: 2;
      .checkbox__list{
        flex-wrap: wrap;
      }
    }
    &:nth-child(3){
      order: 1;
      .checkbox__list{
        flex-wrap: wrap;
      }
    }
    &:nth-child(4){
      order: 3;
      .filter__label{
        text-align: center;
      }
    }
    &:nth-child(5){
      order: 4;
    }
  }

  .field__sep {
    margin-right: 22px;
    margin-left: 22px;
  }

  .checkbox__label{
    br{
      display: none;
    }
  }

  .area__field input{
    width: 105px;
  }

  .checkbox__item{
    margin-bottom: 4px;
  }

  .checkbox__item:not(:last-child) {
    margin-right: 20px;
  }

  .area__fields{
    justify-content: center !important;
    width: 100%;
  }

  .checkbox__item-indent{
    margin-right: -40px !important;
  }

  .checkbox__vip-box {
    left: 60px;
    bottom: -14px;
  }

  .filter-floors-sep {
    width: 100%;
    margin-bottom: 38px;
  }

  .sc__results .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .app__item-card{
    margin-left: -48px;
    margin-right: -48px;
  }

  .app__item-card{
    padding: 48px;
  }

  .app__item-card-left {
    padding-left: 0;
  }

  .app__item-card-right-top{
    width: 100%;
  }

  .app__item-details{
    margin-bottom: 24px;
  }

  .app__item-card-building{
    margin: 0 auto 36px;
  }

  .app__item-card-position-title {
    text-align: center;
  }

  .app__item-data {
    margin-bottom: 36px;
  }

  .app__item-card-position {
    padding-left: 0;
  }

  .app__item-card-actions{
    width: 100%;
  }

  .app__item-card-schema {
    height: auto;
    margin-bottom: 36px;
  }

  .pager__wrap {
    width: 100%;
  }

  .btn__small {
    height: 48px;
    padding: 0 14px;
    font-size: 16px;
    line-height: 16px;
  }

  .app__item-card-compas{
    width: 96px;
    margin: 0 auto;
    img{
      display: block;
      width: 100%;
    }
  }

  .app__sorting-box {
    margin-bottom: 12px;
  }

  .app__item-card-type {
    margin-bottom: 36px;
  }

  .app__list-table:before{
    display: none !important;
  }

  .view-indent .container {
    padding-top: 72px;
  }

  .sorting__item {
    font-size: 11px;
    line-height: 14px;
  }

  .app__item-list-data {
    font-size: 10px;
    line-height: 13px;
    height: 42px;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .app__item-list{
    .row__custom{
      margin-right: -5px;
      margin-left: -5px;
    }
    .col__custom{
      padding-right: 5px;
      padding-left: 5px;
      &:nth-child(1){
        flex: 0 0 13%;
        max-width: 13%;
      }
      &:nth-child(2){
        flex: 0 0 16.6666%;
        max-width: 16.6666%;
      }
      &:nth-child(3){
        flex: 0 0 16.6666%;
        max-width: 16.6666%;
      }
      &:nth-child(4){
        flex: 0 0 13%;
        max-width: 13%;
      }

      &:nth-child(5){
        flex: 0 0 20%;
        max-width: 20%;
      }

      &:nth-child(6){
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }

  .sorting__value-rooms {
    margin-left: 0;
  }

  .app__sorting-list{
    .row__custom{
      margin-right: -5px;
      margin-left: -5px;
    }
    .col__custom{
      padding-right: 5px;
      padding-left: 5px;
      &:nth-child(1){
        flex: 0 0 13%;
        max-width: 13%;
      }
      &:nth-child(2){
        flex: 0 0 16.6666%;
        max-width: 16.6666%;
      }
      &:nth-child(3){
        flex: 0 0 16.6666%;
        max-width: 16.6666%;
      }
      &:nth-child(4){
        flex: 0 0 13%;
        max-width: 13%;
      }

      &:nth-child(5){
        flex: 0 0 20%;
        max-width: 20%;
      }

      &:nth-child(6){
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }

  .app__item-floor{
    white-space: nowrap;
  }

  .app__item-floor {
    padding-left: 5px;
  }

  .sorting__value-floor {
    padding-left: 8px;
  }

  .app__item-rooms {
    margin-left: 0;
  }


  .icon__arrow {
    position: relative;
    z-index: 5;
    min-width: 12px;
    margin-left: 1px;
  }

  .filter__col:nth-child(3){
    margin-bottom: 36px !important;
  }

  .footer__nav{
    li{
      white-space: nowrap;
    }
  }

  .icon__arrow {
    width: 12px;
    height: 5px;
  }

  .filter__col{
    &:last-child{
      margin-bottom: 0;
    }
  }

  .filter__form .row {
    margin-bottom: 0;
  }

  .view__item-bl{
    width: 72px;
    margin-left: auto;
    margin-right: auto;
  }

  .app__list-table .app__item{
    border-top: 1px solid #B4B4B4;
    &:not(:last-child){
      border-bottom: none;
    }
  }

  .sorting__item {
    font-size: 10px;
  }

  .footer{
    .container{
      padding-left: 37.5px;
      padding-right: 37.5px;
    }
  }

  .filter__facade-left{
    width: 194px;
    margin-right: auto;
    margin-left: auto;
  }

  .decor-line-1{
    height: 1px;
    right: 100%;
    margin-right: 12px;
    background: #A57C52;
    top: 50%;
    position: absolute;
    width: 800px;
  }

  .decor-line-2{
    height: 1px;
    left: 100%;
    margin-left: 12px;
    background: #A57C52;
    top: 50%;
    position: absolute;
    width: 800px;
  }

  .page-pent{
    .content__box-indent {
      padding-top: 0;
    }
    .static__img {
      margin: 0 -48px;
    }
  }

  .sc__3d{
    .text{
      text-align: left;
      br{
        display: none;
      }
    }
  }

  .sc__screen:not(:last-child) {
    margin-bottom: 48px;
  }

  .app__item-card-pent .app__item-card-right {
    margin-left: 0;
    padding-right: 0;
  }

  .app__item-card-pent .app__item-card-right-header {
    padding-left: 0;
    text-align: center;
    width: 100%;
  }

  .app__item-card-right-header .app__item-data-info {
    width: 100%;
  }

  .app__item-card-pent .app__item-card-schema {
    height: 700px;
    width: auto;
    padding-bottom: 0;
    justify-content: flex-start !important;
    align-items: flex-end !important;
    margin-bottom: 0;
    margin-right: -48px;
    margin-left: -48px;
    overflow-x: auto;
    img {
      max-width: 20000%;
      display: block;
      max-height: 100%;
      width: auto;
      height: 100%;
    }
  }

  .schema__shift{
    min-width: 48px;
  }

  .bank__list {
    margin-bottom: 0;
  }

  .bank__item {
    margin-bottom: 12px;
  }

  .bank__list .row {
    margin-bottom: -12px !important;
  }

  .sc__buy, .sc__brokers{
    .content__box:first-child{
      .sc__subtitle{
        font-size: 30px;
        line-height: 42px;
      }
    }
    .text{
      text-align: left;
    }
  }

  .sc__brokers{
    .content__box-indent{
      padding-top: 0;
    }
  }

  .map__box {
    padding-left: 0;
  }

  .sc__contacts-inside{
    .container{
      padding-bottom: 0 !important;
    }
  }

  .page-contacts .map {
    height: 540px;
  }

  .sc__contacts-inside{
    .container{
      padding-right: 48px;
      padding-left: 48px;
    }
  }

  .nav__social {
    margin-left: 0;
    justify-content: center !important;
  }

  .sc__contacts-inside .nav__social a {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  .sc__contacts-inside .nav__social a:not(:last-child) {
    margin-right: 48px;
  }

  .map__box{
    margin-top: 48px;
  }

  .contacts__info {
    text-align: center;
  }

  .page-contacts{
    .map {
      margin-left: -48px;
      margin-right: -48px;
    }
  }

  .page-partners{
    .respect__decor{
      width: 130px;
      left: 50%;
      margin-left: -65px;
      top: 50%;
      position: absolute;
      transform: translateY(-46%);
    }

    .sc__subtitle{
      font-size: 30px;
      line-height: 42px;
      text-align: center;
      letter-spacing: 0.2em;
    }
  }

  .header {
    position: absolute;
    left: 0;
    transform: none;
  }

  .nav__box{
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    .container {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 18px;
      height: auto;
      display: block !important;
      padding-bottom: 58px;
    }
  }

  .nav__box-ct{
    .row{
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
  }

  .nav__menu{
    margin-bottom: 24px;
  }

  .nav__menu ul li:not(:last-child) {
    margin-bottom: 8px;
  }

  .nav__menu ul li {
    font-size: 18px;
  }

  .nav__box-left {
    padding-left: 0;
    padding-right: 0;
  }

  .nav__data-box-label{
    margin-bottom: 4px;
  }

  .nav__social a:not(:last-child) {
    margin-right: 36px;
  }

  .nav__data-box:not(:last-child) {
    margin-bottom: 16px;
  }

  .nav__data-box-value {
    font-size: 18px;
    line-height: 120%;
  }

  .nav__close {
    width: 70px;
    height: 70px;
    right: -7px;
    top: -3px;
    path{
      stroke: #000;
    }
  }

  .nav__logo{
    width: 54px;
    height: 54px;
    display: block;
    margin: 0 auto 24px;
    img{
      display: block;
      width: 100%;
    }
  }

  .progress__sl-box{
    .nav__sl-arrow {
      top: 210px;
      margin-top: -36px;
    }
    .nav__sl-arrow-left{
      margin-right: 0;
      right: auto;
      left: -36px;
    }

    .nav__sl-arrow-right{
      margin-left: 0;
      left: auto;
      right: -36px;
    }
  }

  .inside__schema-box{
    margin-bottom: 48px;
  }



  .modal__infra-img{
    margin-bottom: 48px;
  }

  .modal__box {
    width: 550px;
  }

  .box__3d{
    img{
      opacity: 1;
    }
  }

  .box__3d-actions .btn__custom {
    min-width: 0;
    padding: 0 30px;
  }

  .sc__filter-params .filter__nav-box:before{
    left: auto;
  }

  .years__months{
    flex-wrap: wrap;
    margin-right: -0.6rem;
    margin-left: -0.6rem;
  }

  .years__month {
    width: 20%;
    padding-right: 0.6rem;
    padding-left: 0.6rem;
    margin-right: 0 !important;
    margin-bottom: 1.2rem;
    text-align: center;
  }

  .app__item-slider-info {
    padding: 0 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .app__item-card--slider {
    .app__item-card-type {
      padding-left: 0;
      text-align: center;
    }
    .app__item-data{
      flex-wrap: wrap;
      .app__item-data-info {
        width: 100%;
        margin-right: 0 !important;
        text-align: center;
        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
    }
  }

  .app__item-card-schema-item {
    &:last-child{
      img{
        margin-bottom: 20px !important;
      }
    }
  }

  .pent-2, .pent-3 {
    .app__item-card-actions {
      position: relative;
      bottom: 0;
      right: 0;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 24px;
    }
  }

  .pent__slide-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .app__item-card-pent {
    .app__item-card-type{
      margin-bottom: 32px;
    }
  }

  .pent__btn-col {
    &:not(:last-child){
      margin-bottom: 12px;
    }
  }

  .pent-4 .pent__slide-col--1 {
    padding-top: 0;
  }

  .pent-1 .app__card-box:not(:last-child), .pent-4 .app__card-box:not(:last-child) {
    margin-bottom: 32px;
  }

  .pent-4 .pent__slide-1 .pent__slide-col--4 {
    margin-top: 24px;
    padding-bottom: 0;
  }

  .pent-4{
    .app__item-card-schema{
      margin-top: 24px;
    }
  }

  .pent-1 .pent__slide--3 .pent__slide-col--1, .pent-4 .pent__slide--3 .pent__slide-col--1 {
    margin-bottom: auto;
    padding-top: 0;
  }

  .pent-1 .app__item-card-right, .pent-4 .app__item-card-right {
    position: relative;
    right: 0;
    height: auto;
    bottom: 0;
    width: 100%;
    margin-top: 24px;
  }

  .pent-1 .app__item-card-dop, .pent-4 .app__item-card-dop {
    margin-bottom: 0;
  }

  .pent__slide-actions{
    flex-wrap: wrap;
    .btn__small {
      width: 100%;
      &:not(:last-child){
        margin-bottom: 12px;
      }
    }
  }

  .pent__slide-col--2 .btn__small:not(:last-child) {
    margin-right: 0;
  }

  .pent-3 {
    padding: 48px !important;
  }

  .pent-3 .app__card-box:not(:last-child) {
    margin-bottom: 32px;
  }

  .pent-3 .app__item-card-right {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
  }

  .pent-3 .app__item-card-schema {
    margin-top: 32px;
  }

  .video__frame {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    .video-js{
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
    }
  }

  .pent-1 .pent__slide-col--2 .app__item-card-schema, .pent-4 .pent__slide-col--2 .app__item-card-schema {
    margin-bottom: 10px;
  }

  .pent-1 {
    .pent__slide--2 {
      .pent__schema-sm {
        margin-bottom: 40px;
        margin-top: 40px !important;
      }
    }

    .app__item-data-info {
      flex-wrap: wrap;
    }
  }

  .pent__btn-col--1{
    margin-top: 30px !important;
  }

  .app__item-card-pent .pent__schema-sm{
    padding: 0 48px;
  }

  .app__item-card-pent .app__item-card-schema {
    margin-left: 0;
    margin-right: 0;
  }

  .pent__slider {
    margin-right: -48px;
    margin-left: -48px;

    .pent__slide-col--1{
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  .pent-1 .pent__slide--2 .pent__slide-actions {
    margin-top: 21px;
    padding: 0 48px;
  }

  .pent-4 {
    .pent__slider .slick-list {
      overflow: hidden;
    }
  }

  .pent-3 .app__item-card-left {
    padding-left: 48px;
  }

  .pent-3 {
    padding: 48px 0 !important;

    .app__item-card-type {
      padding-left: 48px;
    }

    .pent__schema-sm {
      max-width: 100%;
    }

    .pent__btn-col {
      width: 100%;
    }

    .pent__btn-col:not(:last-child) {
      margin-right: 0;
    }

    .pent__btn-list {
      flex-wrap: wrap;
      padding: 0 48px;
    }
  }

  .sorting__item {
    font-size: 8px;
  }

  .app__list-table {
    margin-left: -30px;
    margin-right: -30px;
  }

  .icon__arrow {
    width: 10px;
    height: 4px;
  }

  .sorting__item {
    white-space: normal;
    line-height: 12px;
  }

  .app__item-list-data {
    &:nth-child(1){
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 24px !important;
    }

  }

  .app__item-list .row__custom {
    align-items: center;
  }

  .app__item-card--slider .app__item-data .app__item-data-info:not(:last-child) {
    margin-bottom: 10px;
  }

}

@media screen and (max-width: 767px){
  .container{
    padding-left: 37.5px;
    padding-right: 37.5px;
  }

  .years__tabs{
    flex-wrap: wrap;
    padding-left: 10.5px;
    padding-right: 10.5px;
    margin-bottom: 24px;
  }
  .years__tab{
    width: 50%;
    min-width: 50%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 24px;
    &:nth-child(2n+1){
      text-align: left;
    }
    &:nth-child(2n){
      text-align: right;
    }
  }

  .progress__sl-box{
    margin-left: -37.5px;
    margin-right: -37.5px;
  }

  .years__tabs-ct {
    margin-bottom: 48px;
  }

  .progress__sl-item-img{
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    img{
      display: block;
      width: 100%;
      position: relative;
    }
  }

  .pr__sl-date{
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    height: 72px;
  }

  .years__tabs {
    flex-direction: row !important;
  }

  .progress__sl-item-descr {
    margin-top: 0;
    padding: 48px 48px 0 48px;
  }

  .progress__sl-box .nav__sl-arrow-left {
    left: 0;
  }

  .progress__sl-box .nav__sl-arrow {
    top: auto;
    bottom: 0;
    margin-top: 0;
  }

  .progress__sl {
    font-size: 0;
  }

  .progress__sl-box .nav__sl-arrow-right {
    right: 0;
  }

  .pr__actions {
    bottom: auto;
    position: relative;
  }

  .progress__sl-item {
    padding-right: 0;
    padding-left: 0;
  }

  .progress__sl-row {
    margin-right: 0;
    margin-left: 0;
  }

  .sc__infra-inside{
    .container {
      padding-top: 0;
    }
  }

  .brad__nav {
    position: relative;
    left: 0;
    margin-left: 0;
    width: 100%;
    top: 0;
    margin-bottom: 58px;
    margin-top: 50px;
  }

  .sc__infra-inside{
    .container {
      padding-bottom: 65px;
    }
  }

  .inside__schema{
    width: 743px;
  }

  .inside__schema-bl{
    overflow-x: auto;
  }

  .brad__nav ul li {
    margin-left: 50px !important;
    margin-right: 50px !important;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    font-size: 22px;
    line-height: 30px;
  }

  .inside__schema-box{
    position: relative;
    height: 422px;
    margin-right: -48px;
    margin-left: -48px;
  }

  .modal__box {
    width: 100%;
    padding: 120px 48px 96px;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 100vh;
  }

  .modal__infra-img{
    margin-bottom: 24px;
  }

  .infra__info-list {
    padding-left: 0;
  }

  .infra__info-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .modal__close{
    width: 64px;
    height: 64px;
    right: 24px;
    top: 24px;
  }

  .outside__schema-img {
    margin-left: 0;
    margin-right: 0;
  }

  .infra__info-list-outside {
    padding-left: 0 !important;
    margin-top: 0;
  }

  .outside__schema-box{
    margin-bottom: 50px;
    border-top: 1px solid #B4B4B4;
    border-bottom: 1px solid #B4B4B4;
    font-size: 0;
  }

  .outside__schema-bl{
    height: 640px;
    overflow-x: auto;
    overflow-y: auto;
  }

  .outside__schema-box {
    margin-left: -48px;
    margin-right: -48px;
  }

  .sc__infra-inside{
    .text{
      margin-top: -10px;
      margin-bottom: 48px;
    }
  }

  .modal__title {
    font-size: 30px;
    line-height: 120%;
  }

  .modal__form{
    .modal__box {
      width: 100%;
      padding: 120px 48px 96px;
    }
  }

  .form__item:not(:last-child) {
    margin-bottom: 30px;
  }

  .form__fields {
    margin-bottom: 54px;
  }

  .form__field input {
    height: 40px;
  }

  .sc__about-comfort{
    .container{
      padding-bottom: 48px;
    }
  }

  .floor__schema {
    padding-right: 0;

  }

  .floor__schema-list{
    margin-left: -30px;
    margin-right: -30px;
  }

  .floor__schema-img{
    padding-right: 0;
  }

  .app__item-card-left {
    height: auto;
  }

  .app__item-card-pent{
    .app__item-card-schema img {
      padding: 0 20px;
    }
    .app__item-data-label {
      font-size: 14px;
    }
    .pent__schema-sm {
      width: 100%;
      position: relative;
      right: 0;
      left: 0;
      top: 0;
      margin-top: 20px;
      bottom: 0;
    }
    .app__item-card-dop {
      position: relative;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      margin-top: 20px;
    }
  }


  .pent-3{
    .app__card-box:first-child {
      margin-bottom: 36px !important;
    }
    .app__item-card-schema {
      max-width: 20000px;
    }
  }

  .footer__ct {
    margin-bottom: 37px;
  }

  .docs__item{
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }

  .docs__list{
    padding-left: 18px;
  }

  .modal__label, .form__after a, .modal__label a{
    font-size: 14px;
    line-height: 22px;
  }

  .lk__nav ul li{
    margin-right: 0 !important;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    &:after{
      display: none;
    }
    &.active{
      a{
        &:after{
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(103.19deg, #946C4C -10.17%, #BD9E7D 52.11%, #946C4C 108.66%);
          position: absolute;
          height: 2px;
        }
      }
    }
    a{
      display: flex;
      align-items: center;
      height: 40px;
      position: relative;
    }
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }

  .form__fields {
    margin-bottom: 36px;
  }

  .lk__nav {
    position: relative;
    top: 0;
    padding-bottom: 48px;
  }

  .lk__box{
    .form__action{
      max-width: 280px;
      margin-top: 54px;
    }
  }

  .scroll-gap-mb{
    min-width: 37.5px;
    height: 1px;
  }

  .deals__tb{
    margin-left: -37.5px;
    margin-right: -37.5px;
    padding-left: 37.5px;
    overflow-x: auto;
    display: flex;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .deals__tb-scroll{
    min-width: 840px;
  }

  .btn__custom-small{
    letter-spacing: 0.15em;
  }

  .lk__section{
    padding-bottom: 48px;
  }

  .lk__section{
    .sc__subtitle {
      font-size: 34px;
      line-height: 120%;
      letter-spacing: 0.2em;
    }
    padding-top: 48px;
  }

  .ct__item-col{
    width: 100%;
    min-width: 100%;
  }

  .ct__list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -24px;
  }

  .ct__item-label{
    display: flex;
    justify-content: center;
    text-align: center;
    height: 64px;
    margin-bottom: 24px;
    align-items: center;
  }

  .ct__item{
    width: 50%;
    min-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
  }

  .ct__item-value{
    text-align: center;
    margin-bottom: 24px;
  }

  .ct__item-name {
    text-align: center;
    margin-bottom: 24px;
  }

  .ct__item{
    padding-top: 0;
    padding-bottom: 0;
  }

  .deal__form-bl .checkbox__label{
    font-size: 14px;
    line-height: 24px;
  }

  .deal__form-box{
    padding: 36px;
  }

  .lk__section--dop{
    .sc__subtitle {
      font-size: 23px;
      line-height: 42px;
    }
  }

  .deal__form-bl{
    width: 100%;
    .checkbox__item{
      width: 100%;
    }
    .checkbox__item:not(:last-child){
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  .form__action-wrap {
    margin-top: 36px;
    .form__action{
      width: 100%;
    }
    position: relative;
    bottom: 0;
  }

  .dc__item-icon{
    margin-left: -6px;
  }

  .lk__section--docs{
    padding-bottom: 0;
  }

  .dc__item-bl {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 168px;
  }

  .deal__form-bl .checkbox__list {
    flex-wrap: wrap;
    margin-bottom: 36px;
  }

  .news__item--special{
    padding-top: 48px;
  }

  .news__item-img{
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    margin-bottom: 12px;
    order: -1;
  }

  .news__item-bl{
    flex-wrap: wrap;
  }

  .news__list .deal__form-box{
    margin: -1px -20px;
  }

  .deal__form-box{
    padding: 36px 24px;
    .form__action-wrap{
      margin-top: 24px;
    }
    .form__action-wrap .btn__custom{
      line-height: 120%;
    }
  }

  .years__tabs {
    justify-content: flex-start !important;
  }

  .post__body{
    img{
      margin: 48px auto 48px;
    }
    h2{
      margin: 48px 0 48px 0;
      font-size: 34px;
      line-height: 140%;
      text-align: center;
    }
    blockquote{
      margin: 48px 0;
      padding: 48px 0;
      letter-spacing: -0.02em;
      text-align: left;
      &:before{
        left: 0;
        margin-left: 0;
        width: 100%;
      }
      &:after{
        left: 0;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .post__box-3 {
    flex-wrap: wrap;
    .post__box-right {
      width: auto;
      min-width: 0;
      margin-left: -37.5px;
      margin-right: -37.5px;
      margin-top: 48px;
    }
  }

  .years__months{
    justify-content: flex-start !important;
  }

  .years__month {
    width: 33.333%;
    margin-bottom: 12px;
  }

  .years__months{
    margin-bottom: 36px;
  }

  .modal__app .modal__box{
    padding-bottom: 0;
    padding-top: 0;
  }

  .modal__close{
    z-index: 10;
  }

  .gr-item{
    & > *{
      text-align: center;
      display: inline;
    }
  }

  .subscribe__box {
    margin-bottom: 16px;
    justify-content: center !important;
  }

  .sc__progress {
    .container {
      padding-bottom: 0 !important;
    }
  }

  .sc__about-history {
    .sc__subtitle {
      font-size: 30px;
    }
  }

  #architecture, #engineering {
    .sc__header {
      margin-right: -13px;
      margin-left: -13px;
    }
  }

  .video__frame {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: -40px;
    margin-right: -40px;
    width: auto !important;
    padding-top: 70.25%;
  }

  .video__frame .tugrenev-skin .vjs-playback-rate .vjs-playback-rate-value {
    font-size: 18px;
  }

  .video__frame .tugrenev-skin .vjs-time-control {
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .video__frame .tugrenev-skin .vjs-fullscreen-control.vjs-control.vjs-button, .video__frame .tugrenev-skin .vjs-play-control.vjs-control.vjs-button {
    width: 46px;
  }

  .video__frame .tugrenev-skin .vjs-button > .vjs-icon-placeholder:before {
    font-size: 28px;
  }

  .video__frame .tugrenev-skin .vjs-big-play-button {
    width: 74px;
    height: 74px;
    margin-left: -37px;
    margin-top: -37px;
  }

  .video__frame .tugrenev-skin .vjs-big-play-button .vjs-icon-placeholder {
    width: 46px;
    height: 46px;
    margin-left: -23px;
    margin-top: -23px;
  }

  .video__frame .tugrenev-skin .vjs-poster:after {
    right: 16px;
    top: 16px;
    width: 50px;
    height: 50px;
  }

  .progress__sl-item-img img {
    height: 40rem;
    object-fit: cover;
  }

  .post__body figcaption {
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
    margin-top: -35px;
    margin-bottom: 24px;
    text-align: center;
  }

  .post__after{
    margin-top: 40px;
  }

  .hd__item--lang {
    position: absolute;
    right: 55px;
    top: 20px;
    z-index: 10;
    padding-right: 0;
    border-right: none;

    &:after{
      display: none !important;
    }
  }

  .nav__data {
    .hd__item--lang{
      position: relative;
      padding-left: 0;
      margin-left: auto;
      top: 0;
      margin-right: auto;
      right: auto;

      .icon__en{
        &:before{
          background-image: url("../img/icons/icon-en-gr.svg");
        }
      }

      .icon__ru{
        &:before{
          background-image: url("../img/icons/icon-ru-gr.svg");
        }
      }
    }
  }

  .pent-5 .app__item-card-right-title{
    margin-top: 23px;
  }

  .pent-5 .app__item-card-schema {
    max-width: 1000% !important;
    margin-top: 5px !important;
  }

  .filter__form-btn {
    min-width: 115px;
    margin: 32px 0 -15px;
    height: 32px;
    padding: 0 14px;
    font-size: 11px;
    line-height: 16px;
  }

  .app__item-card-pent .app__item-data-label {
    font-size: 18px;
  }

  .pent-3 {
    .pent__media-box {
      padding-right: 48px;
      padding-left: 48px;
    }
  }
}
